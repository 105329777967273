<script setup lang="ts">
import gsap from 'gsap';
import { ref, watch, onMounted, type ComponentPublicInstance } from 'vue';
import IconPlus from './assets/plus.svg';

const props = withDefaults(defineProps<{
  title: string;
  isOpen?: boolean;
}>(), {
  isOpen: false,
});

const isOpen = ref(props.isOpen);
const content = ref<HTMLElement | null>(null);
const icon = ref<ComponentPublicInstance | null>(null);

const open = () => isOpen.value = true;
const close = () => isOpen.value = true;
const toggle = () => isOpen.value = !isOpen.value;

const duration = 0.3;
const ease = 'sine.out';

watch(isOpen, (isOpen) => {
  gsap.to(content.value, {
    height: isOpen ? 'auto' : 0,
    duration,
    ease,
  });

  if (!icon.value?.$el) return;

  gsap.to(icon.value.$el, {
    rotation: isOpen ? -45 : 0,
    duration,
    ease,
  });
});

onMounted(() => {
  gsap.set(content.value, {
    height: isOpen.value ? 'auto' : 0,
    duration,
    ease,
  });

  if (!icon.value?.$el) return;

  gsap.set(icon.value.$el, {
    rotation: isOpen.value ? -45 : 0,
    duration,
    ease,
  });
});

defineExpose({
  isOpen,
  open,
  close,
  toggle,
});
</script>

<template>
  <div
    class="border-b border-primary-200 py-32"
    data-testpl="accordion-item"
  >
    <button
      class="flex w-full items-center justify-between gap-16"
      type="button"
      data-testpl="accordion-btn"
      @click="isOpen = !isOpen"
    >
      <span class="text-left text-20">
        {{ title }}
      </span>

      <IconPlus
        ref="icon"
        class="shrink-0 text-primary-500"
      />
    </button>

    <div
      ref="content"
      class="overflow-hidden"
      data-testpl="accordion-content"
    >
      <div class="pt-24">
        <slot />
      </div>
    </div>
  </div>
</template>
