<script setup lang="ts">
import { useSlots, computed } from 'vue';
import BaseSpinner from '../BaseSpinner/BaseSpinner.vue';
import type {
  BaseButtonSize,
  BaseButtonTheme,
  BaseButtonColor,
  BaseButtonType,
} from './types';

const props = withDefaults(defineProps<{
  size?: BaseButtonSize;
  theme?: BaseButtonTheme;
  color?: BaseButtonColor;
  type?: BaseButtonType;
  loading?: boolean;
  disabled?: boolean;
  fakeDisabled?: boolean;
  href?: string;
  target?: string;
  clickable?: boolean;
  animated?: boolean;
  rounded?: boolean;
}>(), {
  size: 'md',
  theme: 'filled',
  color: 'default',
  type: 'button',
  loading: false,
  disabled: false,
  fakeDisabled: false,
  href: '',
  target: '_self',
  clickable: true,
  animated: false,
  rounded: false,
});

const emit = defineEmits<{
  'click': [value: Event];
}>();

const slots = useSlots();

const tag = computed(() => {
  if (!props.clickable) {
    return 'span';
  }

  if (props.href) {
    return 'a';
  }

  return 'button';
});

const attributes = computed(() => {
  if (!props.clickable) {
    return {};
  }

  if (props.href) {
    return { href: props.href, target: props.target };
  }

  return { type: props.type, disabled: props.disabled };
});

const disabled = computed(() => props.disabled || props.fakeDisabled);

const sizeClass = computed(() => ({
  // Sizes of button with text
  'h-[49px] px-16 text-18 font-bold': slots.default && props.size === 'sm',
  'p-20 text-18 font-bold': slots.default && props.size === 'md',

  // Sizes of button without text
  'w-[49px] h-[49px]': !slots.default && props.size === 'sm',
  'w-[63px] h-[63px]': !slots.default && props.size === 'md',
}));

const themeClass = computed(() => ({
  'theme-filled': props.theme === 'filled',
  'theme-outlined': props.theme === 'outlined',
}));

const themeColorMap = {
  filled: {
    'default': 'filled-primary',
    'primary': 'filled-primary',
    'black': 'filled-black',
  },
  outlined: {
    'default': 'outlined-primary',
    'primary': 'outlined-primary',
  },
} as {
  [theme in BaseButtonTheme]: {
    [color in BaseButtonColor]: string
  }
};

const colorClass = computed(() => {
  return themeColorMap[props.theme]?.[props.color]
    || themeColorMap[props.theme]?.default
    || '';
});

const onClick = (e: Event) => {
  if (props.loading) {
    return;
  }
  emit('click', e);
};
</script>

<template>
  <component
    :is="tag"
    v-bind="attributes"
    class="group
        inline-block
        cursor-pointer
        text-14
        font-bold
        outline-none
        transition
        duration-200
        disabled:cursor-default"
    :class="[
      sizeClass,
      themeClass,
      colorClass,
      {
        'opacity-50': disabled,
        'rounded-full': rounded,
        'rounded-[5px]': !rounded,
        'text-center': !slots['icon-left'] && !slots['icon-right'],
        'text-left': slots['icon-right'],
        'text-right': slots['icon-left'],
      }
    ]"
    @click="onClick"
  >
    <span
      class="relative
          flex
          h-full
          w-full
          items-center
          justify-center
          gap-20"
    >
      <span
        v-if="slots['icon-left']"
        class="shrink-0"
        :class="{
          'opacity-0': loading,
          'transition-transform duration-200 group-hover:-translate-x-6': animated && !disabled,
        }"
      >
        <slot name="icon-left" />
      </span>

      <span
        v-if="slots.default"
        :class="{
          'opacity-0': loading,
          'mr-auto': slots['icon-left'],
        }"
      >
        <slot />
      </span>

      <span
        v-if="slots['icon-right']"
        class="ml-auto shrink-0"
        :class="{
          'opacity-0': loading,
          'transition-transform duration-200 group-hover:translate-x-6': animated && !disabled,
        }"
      >
        <slot name="icon-right" />
      </span>

      <span
        v-if="loading"
        class="pointer-events-none
            absolute
            inset-0
            flex
            items-center
            justify-center"
      >
        <BaseSpinner class="size-20" />
      </span>
    </span>
  </component>
</template>

<style lang="scss" scoped>
.filled-primary {
  @apply
    text-white
    bg-primary-300;
}

.filled-black {
  @apply
    text-white
    bg-black;
}

.outlined-primary {
  @apply
    text-black
    border
    border-primary-200;
}
</style>
