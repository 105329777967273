export const tools = [
  {
    title: 'Nutritional Coaches',
    text: 'Access 1-1 coaching support with packages starting at $65 per session',
  },
  {
    title: 'Daily Tracking',
    text: 'Monitor your weight, movement & nutrition inside of your patient portal',
  },
];

export const help = [
  {
    title: 'Nurse Video Support',
    text: 'Receive 1-1 video support from our nursing team to guide you through your treatment plan and address any questions',
  },
  {
    title: 'Coaching Platform',
    text: 'Monitor your weight, movement, and nutrition with easy-to-use tools inside your patient portal',
  },
  {
    title: 'Free Shipping',
    text: 'We’ll deliver your medication right to your door—skip the lines',
  },
];
