import { CheckoutForm, VariantForm } from '@models/checkout';
import { useQuizStore } from '@stores/quiz';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredUnless, minLength, maxLength, sameAs  } from '@vuelidate/validators';
import { MaybeRefOrGetter, ref, toRef } from 'vue';

export const useForms = (
  isBillingAddressSameProp: MaybeRefOrGetter<boolean>,
  isNewPaymentMethod: MaybeRefOrGetter<boolean>,
) => {
  const quizStore = useQuizStore();
  const isBillingAddressSame = toRef(isBillingAddressSameProp);

  // Checkout
  const checkoutForm = ref<CheckoutForm>({
    items: [],
    coupon: '',
    shippingAddress: {
      firstName: '',
      lastName: '',
      phone: '',
      line1: '',
      line2: '',
      city: '',
      zip: '',
      state_code: '',
    },
    billingAddress: null,
    tokenId: null,
    paymentSourceId: null,
    submissionId: quizStore.submissionId!,
    eventId: crypto.randomUUID(),
    source: 'quiz',
  });

  const checkoutFormRules = {
    paymentSourceId: { required: helpers.withMessage('Payment method is required', requiredUnless(isNewPaymentMethod)) },
    shippingAddress: {
      firstName: { required },
      lastName: { required },
      phone: {
        required,
        phone: helpers.withMessage(
          'Phone number must be in the format +1 (XXX) XXX-XXXX',
          (value: string) => /^\+1 \(\d{3}\) \d{3}-\d{4}$/.test(value),
        ),
      },
      line1: { required },
      city: { required },
      zip: { required, minLength: minLength(5), maxLength: maxLength(10) },
      state_code: { required },
    },
    billingAddress: {
      firstName: { required: requiredUnless(isBillingAddressSame) },
      lastName: { required: requiredUnless(isBillingAddressSame) },
      phone: {
        required: requiredUnless(isBillingAddressSame),
        phone: helpers.withMessage(
          'Phone number must be in the format +1 (XXX) XXX-XXXX',
          (value: string) => {
            if (isBillingAddressSame.value) {
              return true;
            }
            return /^\+1 \(\d{3}\) \d{3}-\d{4}$/.test(value);
          },
        ),
      },
      line1: { required: requiredUnless(isBillingAddressSame) },
      city: { required: requiredUnless(isBillingAddressSame) },
      zip: { required: requiredUnless(isBillingAddressSame), minLength: minLength(5), maxLength: maxLength(10) },
      state_code: { required: requiredUnless(isBillingAddressSame) },
    },
  };

  const checkout$ = useVuelidate(checkoutFormRules, checkoutForm);

  // Variant
  const variantForm = ref<VariantForm>({
    period: { value: null, unit: null },
  });

  const variantFormRules = {
    period: {
      value: { required },
      unit: { required },
    },
  };

  const variant$ = useVuelidate(variantFormRules, variantForm);

  // Checkboxes
  const checkboxesForm = ref({
    agreement: false,
  });

  const checkboxesFormRules = {
    agreement: { required: helpers.withMessage('Agreement is required', sameAs(true)) },
  };

  const checkboxes$ = useVuelidate(checkboxesFormRules, checkboxesForm);

  return {
    checkoutForm,
    checkout$,
    variantForm,
    variant$,
    checkboxesForm,
    checkboxes$,
  };
};
