<script setup lang="ts">
import AbortIcon from '@assets/icons/abort.svg';
import HmrLogo from '@assets/icons/hmr-logo.svg';
import LogoIcon from '@assets/icons/jrnys-logo.svg';
import ProfileIcon from '@assets/icons/profile.svg';
import RestartIcon from '@assets/icons/restart.svg';
import PromoCode from '@components/PromoCode/PromoCode.vue';
import BaseButton from '@components/base/BaseButton.vue';
import { NavigationMenu } from '@components/navigation-menu';
import imgDoctors from '@components/quiz_v2/QuizCheckout/assets/img/checkout-info-doctors.webp';
import imgPhone from '@components/quiz_v2/QuizCheckout/assets/img/checkout-welcome-phone.webp';
import imgTirzepatide from '@components/quiz_v2/QuizCheckout/assets/img/checkout-welcome-tirzepatide.webp';
import videoDoctor from '@components/quiz_v2/QuizCheckout/assets/video/checkout-welcome-doctor.mp4';
import QuizLoader from '@components/quiz_v2/QuizLoader/QuizLoader.vue';
import QuizStep from '@components/quiz_v2/QuizStep/QuizStep.vue';
import { SITE_HANDLE } from '@models/menu';
import { MODAL_TYPE } from '@models/modals';
import { QUIZ_TYPE, STEP_TYPE, StepAnswer } from '@models/quiz';
import { useQuizStore, useUserStore, useAnalyticsStore } from '@stores/index';
import { watch, ref, computed } from 'vue';

type Props = {
  handle: QUIZ_TYPE;
  promoImage?: string | null;
  promoText?: string | null;
  topBannerText?: string | null;
  topBannerPromocode?: string | null;
}

const props = defineProps<Props>();

const userStore = useUserStore();
const quizStore = useQuizStore();
const analyticsStore = useAnalyticsStore();
const loading = ref(false);

userStore.getInfo().then(() => {
  quizStore.getQuiz(props.handle);
});

const next = (payload?: StepAnswer<any>[], stepId?: number) => {
  if (payload) {
    loading.value = true;
    quizStore.setAnswer(payload, stepId)
      .finally(() => {
        loading.value = false;
      });
  }

  quizStore.next();
};

const siteHandle = window.siteHandle;

const isPromoEnabled = ref(false);

watch(() => quizStore.counter, (counter) => {
  const searchParams = new URLSearchParams(location.search);
  isPromoEnabled.value = counter === 0 && searchParams.get('newyear') !== null;

  window.EF?.configure({ tld: 'jrnys.com' });
  const midQuizCount = Math.ceil(quizStore.steps.length / 2);

  if (counter === 1) {
    window.EF?.conversion({
      aid: 1,
      transaction_id: analyticsStore.efTransactionId,
      adv_event_id: 4, // Quiz Start Conversion
    });
  }
  if (counter === midQuizCount && midQuizCount !== 0) {
    window.EF?.conversion({
      aid: 1,
      transaction_id: analyticsStore.efTransactionId,
      adv_event_id: 5, // Mid Quiz Conversion
    });
  }
}, {
  immediate: true,
});

const isPromoBanner = computed(() => {
  return quizStore.counter === 0
      && (props.promoText || props.promoImage);
});

const preloadedImages = [
  imgPhone,
  imgTirzepatide,
  imgDoctors,
];

const preloadedVideos = [
  videoDoctor,
];
</script>

<template>
  <Teleport to="body">
    <Transition name="slide-animation">
      <PromoCode
        v-if="isPromoEnabled && topBannerText && topBannerPromocode"
        :text="topBannerText"
        :code="topBannerPromocode"
      />
    </Transition>
  </Teleport>

  <div class="grid min-h-screen grid-cols-1">
    <header class="absolute left-0 top-0 z-10 w-full pb-36 pt-30 lg:pt-56">
      <div class="relative">
        <div class="container flex h-32 items-center justify-between gap-16">
          <a
            v-if="siteHandle === SITE_HANDLE.HMRPROGRAM"
            href="https://www.hmrprogram.com/rx-plan"
            target="_blank"
          >
            <HmrLogo class="h-[38px] w-[209px] lg:h-[51px] lg:w-[277px]" />
          </a>
          <a
            v-else
            href="/"
          >
            <LogoIcon class="h-[27px] w-[72px] text-deep-blue lg:h-[31px] lg:w-[82px]" />
          </a>
        </div>

        <div class="absolute right-20 top-0 lg:right-40">
          <NavigationMenu
            v-if="!userStore.info?.isGuest"
            :items="$root.userMenuItems"
          >
            <ProfileIcon class="size-32" />
          </NavigationMenu>

          <button
            v-else
            class="text-turquoise hover:text-deep-blue"
            type="button"
            @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
          >
            <ProfileIcon class="size-32" />
          </button>
        </div>
      </div>
    </header>

    <Transition
      name="fade-animation"
      mode="out-in"
    >
      <QuizLoader v-if="quizStore.loading">
        Hang tight while we prepare your customized treatment
      </QuizLoader>

      <div
        v-else
        class="flex flex-col"
      >
        <Transition
          name="fade-animation"
          mode="out-in"
        >
          <div
            v-if="quizStore.currentStep"
            :key="quizStore.currentStep.id"
            class="grid grow grid-cols-1"
            :class="{
              'xl:grid-cols-2': isPromoBanner,
            }"
          >
            <div
              v-if="isPromoBanner"
              class="flex flex-col bg-primary-300"
            >
              <div
                class="relative grid w-full grow self-end pt-[98px] lg:grid-cols-[600px,1fr] lg:pt-[124px] xl:h-screen xl:max-w-[668px] xl:grid-cols-1"
                :class="{ 'pt-[88px]': promoText && quizStore.counter === 0 }"
              >
                <div
                  v-if="promoText"
                  class="promo-text relative flex max-w-[668px] flex-col gap-8 px-20 pb-20 sm:gap-16 lg:px-40"
                  v-html="promoText"
                />
                <figure
                  v-if="promoImage"
                  class="relative -mt-80 ml-[230px] overflow-hidden"
                >
                  <img
                    class="size-full ml-auto mt-auto max-w-[50vw] object-contain object-right-bottom lg:max-w-[600px]"
                    :src="promoImage"
                    alt=""
                  >
                </figure>
              </div>
            </div>

            <div
              class="flex grow flex-col lg:pt-[124px]"
              :class="promoText && quizStore.counter === 0 ? 'py-[24px]' : 'pb-[156px] pt-[98px]'"
            >
              <QuizStep
                class="my-auto"
                :step="quizStore.currentStep"
                :answers="quizStore.answers[quizStore.currentStep.id]"
                :position="quizStore.counter"
                @next="next"
                @back="quizStore.back"
              />
            </div>
          </div>
        </Transition>

        <Transition
          name="fade-animation"
          mode="out-in"
        >
          <footer
            v-if="!isPromoBanner"
            class="absolute bottom-0 left-0 w-full pb-16 pt-30 sm:pb-30 sm:pt-90"
          >
            <div class="container">
              <div class="grid items-center gap-8 sm:grid-cols-2 sm:gap-16 md:grid-cols-3">
                <div class="sm:text-center md:text-left">
                  <div class="flex flex-wrap items-center gap-x-30">
                    <BaseButton
                      v-if="!quizStore.paid && !quizStore.loading"
                      class="shrink-0 text-warm-grey hover:bg-turquoise hover:text-white"
                      data-testid="quiz-restart-button"
                      text="Restart Quiz"
                      size="sm"
                      @click="quizStore.restart"
                    >
                      <template #icon-left>
                        <RestartIcon class="h-16" />
                      </template>
                    </BaseButton>

                    <BaseButton
                      v-if="!quizStore.paid && !quizStore.loading"
                      class="shrink-0 text-warm-grey hover:bg-turquoise hover:text-white"
                      data-testid="quiz-abort-button"
                      text="Delete Intake"
                      size="sm"
                      @click="quizStore.abort"
                    >
                      <template #icon-left>
                        <AbortIcon class="h-16" />
                      </template>
                    </BaseButton>
                  </div>
                </div>

                <div
                  v-if="(userStore.info?.isGuest && quizStore.currentStep?.type !== STEP_TYPE.AUTH)
                    || quizStore.loading"
                  class="sm:text-center"
                >
                  Already a member?
                  <button
                    class="font-bold hover:text-turquoise"
                    type="button"
                    data-testid="quiz-login"
                    @click="$root.setModal(MODAL_TYPE.LOGIN, true)"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </Transition>
      </div>
    </Transition>

    <div v-show="false">
      <img
        v-for="src in preloadedImages"
        :key="src"
        :src="src"
      >
      <video
        v-for="src in preloadedVideos"
        :key="src"
        :src="src"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
// Slide animation
.slide-animation-enter-active,
.slide-animation-leave-active {
  transition: transform 0.3s;
}

.slide-animation-enter-from,
.slide-animation-leave-to {
  transform: translateY(-100%);
}

// Fade animation
.fade-animation-enter-active,
.fade-animation-leave-active {
  transition: opacity 0.3s;
}

.fade-animation-enter-from,
.fade-animation-leave-to {
  opacity: 0;
}

.promo-text :deep(p) {
  &:nth-child(1) {
    @apply text-[36px] sm:text-[57px] text-white font-light;
  }
  &:nth-child(2) {
    @apply text-[24px] sm:text-[36px] text-black;
  }
  &:nth-child(3) {
    @apply text-[20px] sm:text-[30px] text-white font-light;
  }
}
</style>
