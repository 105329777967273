<script setup lang="ts">
import CloseIcon from '@assets/icons/close-round.svg?component';
import { watch, toRefs, onMounted } from 'vue';

type Props = {
  visibility?: boolean
  zIndex?: number
  contentClass?: string
  closeButton?: boolean
};

const props = withDefaults(defineProps<Props>(), {
  zIndex: 9999,
  contentClass: '',
  closeButton: true,
});

const emit = defineEmits<{
  'update:visibility': [value: boolean];
}>();

const { visibility, contentClass, closeButton } = toRefs(props);

const onClose = (e: MouseEvent) => {
  const target = e.target as HTMLElement;

  document.body.style.overflow = 'visible';
  document.body.style.height = 'auto';

  if (window.getSelection()?.toString()) {
    return;
  }

  if (
    target?.closest('.base-dialog__content')
    && !target?.closest('.base-dialog__close-btn')
  ) {
    return;
  }

  emit('update:visibility', false);
};

const onEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    document.body.style.overflow = 'visible';
    document.body.style.height = 'auto';
    emit('update:visibility', false);
  }
};

watch(visibility, (value) => {
  if (typeof value === 'undefined') {
    return;
  }
  if (value) {
    window.addEventListener('keydown', onEscape);
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
  } else {
    window.removeEventListener('keydown', onEscape);
    document.body.style.overflow = 'visible';
    document.body.style.height = 'auto';
  }
});

onMounted(() => {
  if (visibility.value) {
    window.addEventListener('keydown', onEscape);
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
  }
});
</script>

<template>
  <Transition name="fade">
    <div
      v-show="visibility"
      class="base-dialog"
      data-testpl="dialog-modal"
      :style="{zIndex}"
      @click="onClose"
    >
      <div
        :class="['base-dialog__content', contentClass]"
        data-testpl="dialog-modal-content"
      >
        <button
          v-if="closeButton"
          class="base-dialog__close-btn text-warm-grey hover:text-primary-500"
          type="button"
          data-testid="login-close-button"
          data-testpl="dialog-modal-close-button"
        >
          <CloseIcon class="h-24 w-24 lg:h-32 lg:w-32" />
        </button>

        <slot />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.base-dialog {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow: auto;
  background: rgba(#653434, 0.3);

  @include up('lg') {
    padding: 60px;
  }
}

.base-dialog__content {
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: auto;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--color-white);

  @include up('lg') {
    padding: 60px;
    border-radius: 40px;
  }
}

.base-dialog__successes {
  max-width: 440px;
}

.base-dialog__close-btn {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  padding: 16px;

  @include up('lg') {
    top: 24px;
    right: 24px;
  }
}

.dark-modal {
  background-color: var(--color-deep-blue);
  color: var(--color-white);

  @include up('lg') {
    max-width: 700px;
    padding: 70px 120px;
  }
}
</style>
