<script setup lang="ts">
import SpinnerIcon from '@assets/icons/spinner.svg?component';
import { RenderComponent } from '@components/common';
import BaseDialog from '@components/quiz_v2/BaseDialog/BaseDialog.vue';
import { MODAL_TYPE } from '@models/modals';
import { ref, computed, watch, getCurrentInstance } from 'vue';

type Props = {
  visibility?: boolean
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:visibility': [value: boolean]
}>();

const model = computed({
  get() {
    return props.visibility;
  },
  set(value) {
    emit('update:visibility', value);
  },
});

const internalInstance = getCurrentInstance();
const template = ref<string>('');

const getContent = async (url: string) => {
  const response = await fetch(url, { method: 'GET', cache: 'no-cache' });
  const data = await response.text();

  const parser = new DOMParser();
  const html = parser.parseFromString(data, 'text/html');
  const content = html.querySelector('#product-modal-content');

  const excludedItems = html.querySelectorAll('.product-modal-exclude');
  excludedItems.forEach((item) => item.remove());

  if (!content) {
    location.href = url;
    return;
  }

  renderContent(content);
};

const renderContent = (content: Element) => {
  template.value = content.outerHTML;
};

watch(() => props.visibility, (visibility) => {
  const root = internalInstance?.appContext.config.globalProperties.root;
  const url = root.modalPayload[MODAL_TYPE.PRODUCT_V2];

  if (!visibility) {
    template.value = '';
    return;
  }

  url && getContent(url);
});
</script>

<template>
  <BaseDialog
    v-model:visibility="model"
    class="product-modal"
  >
    <div
      v-if="!template"
      class="www flex min-h-[500px] items-center justify-center lg:pb-40"
    >
      <SpinnerIcon class="h-48 w-48 text-primary-500 lg:h-60 lg:w-60" />
    </div>

    <RenderComponent
      v-else
      :template="template"
    />
  </BaseDialog>
</template>

<style lang="scss" scoped>
.product-modal:deep {
  .base-dialog__content {
    @include up('lg') {
      max-width: 1416px;
      padding: 80px 40px 40px;
    }
  }

  .product-modal-btn {
    display: inline-flex !important;
  }
}
</style>
