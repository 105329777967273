import type { MaskInputOptions } from 'maska';
import { reactive } from 'vue';

export const optionsWeight = reactive<MaskInputOptions>({
  mask: 'ABC.BB',
  tokens: {
    A: { pattern: /[1-9]/ },
    B: { pattern: /[0-9]/ },
    C: { pattern: /[0-9]/, optional: true },
  },
});

export const optionsPhone = reactive<MaskInputOptions>({
  mask: '+1 (999) 999-9999',
  tokens: {
    '9': { pattern: /[0-9]/ },
  },
});

export const optionsPostcode = reactive<MaskInputOptions>({
  mask: ['99999', '99999-9999'],
  tokens: {
    '9': { pattern: /[0-9]/ },
  },
});

export const optionsInteger = reactive<MaskInputOptions>({
  mask: '#',
  tokens: {
    '#': { pattern: /[0-9]/, repeated: true },
  },
});
