<script setup lang="ts">
import BaseModal from '@components/quiz_v2//BaseModal/BaseModal.vue';
import { CartProduct } from '@models/product';
import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
import ProductBadge from '../ProductBadge/ProductBadge.vue';

const props = withDefaults(defineProps<{
  modelValue?: boolean;
  list: CartProduct[];
}>(), {
  modelValue: false,
  list: () => [],
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
  'select': [product: CartProduct];
}>();

const model = useVModel(props, 'modelValue', emit);
const openedProductId = ref<number | null>(null);

const selectProduct = (product: CartProduct) => {
  emit('select', product);
  model.value = false;
};
</script>

<template>
  <BaseModal v-model="model">
    <h1 class="pr-[37px] text-24 font-medium text-primary-400 md:pr-[45px] md:text-28">
      Other Treatment Options
    </h1>
    <p class="mt-20 text-24">
      GLP-1 Injections
    </p>

    <ul class="mt-20 grid gap-20">
      <li
        v-for="product in list"
        :key="product.product.id"
        class="group grid gap-20 rounded-[5px] border p-20"
        :class="{
          'border-primary-100 bg-primary-100': product.product.id === openedProductId,
          'cursor-pointer border-primary-200 transition-colors duration-200 hover:border-primary-300 hover:bg-primary-300': product.product.id !== openedProductId
        }"
        @click="openedProductId = product.product.id"
      >
        <div class="flex items-center justify-between gap-16">
          <h2 class="text-18 font-bold">
            {{ product.product.title }}
          </h2>
          <p
            v-if="product.priceBadge"
            class="rounded-[5px] px-10 py-6 text-12 font-bold text-white"
            :class="{
              'bg-black': product.product.id === openedProductId,
              'bg-primary-400 transition-colors duration-200 group-hover:bg-black': product.product.id !== openedProductId,
            }"
          >
            {{ product.priceBadge }}
          </p>
        </div>

        <template v-if="product.product.id === openedProductId">
          <div
            v-if="product.productDescription"
            class="prose text-14"
            v-html="product.productDescription"
          />

          <div class="flex items-center gap-16">
            <ProductBadge
              v-if="product.supply.value"
              :value="product.supply.value"
            >
              {{ product.supply.label }}
            </ProductBadge>

            <button
              v-if="product.supply.value !== 'outOfStock'"
              type="button"
              class="ml-auto min-w-[97px] rounded-[5px] border border-black p-6 text-12 text-black hover:bg-black hover:text-white"
              @click="selectProduct(product)"
            >
              Select
            </button>
          </div>
        </template>
      </li>
    </ul>
  </BaseModal>
</template>
