<script setup lang="ts">
withDefaults(defineProps<{
  title: string;
  price?: string;
  label?: string;
  isOpen?: boolean;
}>(), {
  price: '',
  label: '',
  isOpen: false,
});

const emit = defineEmits<{
  'click': [];
}>();
</script>

<template>
  <div>
    <div
      v-if="label"
      class="mx-16 inline-block rounded-t-[5px] bg-primary-300 px-16 py-6 text-16 font-bold text-white"
    >
      {{ label }}
    </div>

    <div
      class="rounded-[5px]"
      :class="{
        'border border-primary-500': isOpen,
      }"
      data-testpl="checkout-period"
    >
      <button
        class="relative flex w-full items-center justify-between gap-16 rounded-[5px] p-20"
        :class="{
          'text-primary-500 duration-0': isOpen,
          'border border-primary-200 hover:border-primary-300 hover:bg-primary-300': !isOpen,
        }"
        type="button"
        data-testpl="checkout-period-btn"
        @click="emit('click')"
      >
        <span class="text-left text-18 font-bold">
          {{ title }}
        </span>

        <span
          v-if="price"
          class="text-right"
          :class="{
            'text-18 font-bold': isOpen,
            'text-12': !isOpen,
          }"
        >
          {{ price }}
        </span>
      </button>

      <div
        v-if="isOpen"
        class="-mt-12 p-20"
        data-testpl="checkout-period-content"
      >
        <slot />
      </div>
    </div>
  </div>
</template>
