import { apiCart } from '@api/cart';
import { apiUser } from '@api/user';
import { CardComponent } from '@chargebee/chargebee-js-vue-wrapper';
import { useFetch } from '@helpers/use-fetch';
import { CartItemPayload } from '@models/cart';
import { CartProduct, PRODUCT_TYPE } from '@models/product';
import { VARIANT_TYPE } from '@models/variant';
import { useQuizStore } from '@stores/quiz';
import { omit, sumBy, set, cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toast-notification';
import { useForms } from './composables/useForms';
import { useProfile } from './composables/useProfile';
import { useAnalyticsStore, useCartStore, useUserStore } from '@/js/stores';

export const useCheckoutStore = defineStore('checkout', () => {
  window.Chargebee?.init({
    site: import.meta.env.VITE_CHARGEBEE_API_SITE,
    publishableKey: import.meta.env.VITE_CHARGEBEE_PUBLISHABLE_KEY,
  });

  const toast = useToast();

  const quizStore = useQuizStore();
  const analyticsStore = useAnalyticsStore();
  const cartStore = useCartStore();
  const userStore = useUserStore();

  const { profileUser, profileBillingAddress, profileShippingAddress } = useProfile();

  const products = ref<CartProduct[]>([]);
  const mainProduct = ref<CartProduct | null>(null);

  const membershipProduct = computed(() => {
    return products.value.find((p) => {
      return p.product.productType.includes(PRODUCT_TYPE.DIGITAL)
          && p.product.variants.find((variant) => variant.variantType === VARIANT_TYPE.SINGLE);
    });
  });

  const isBillingAddressSame = ref(true);

  // Payment methods
  const getPaymentMethodsApi = useFetch(apiUser.getPaymentMethods);
  const paymentMethods = computed(() => getPaymentMethodsApi.response.value?.data.paymentMethods || []);
  // const isNewPaymentMethod = computed(() => paymentMethods.value.length === 0);
  const activePaymentMethodId = computed(() => paymentMethods.value.find((item) => item.card.default)?.externalRef || null);
  getPaymentMethodsApi.execute({});

  const isNewPaymentMethod = ref(false);

  watch(paymentMethods, (paymentMethods) => {
    isNewPaymentMethod.value = paymentMethods.length === 0;
  });

  const deletePaymentMethodApi = useFetch(apiUser.deletePaymentMethod);
  // const deletePaymentMethodLoading = computed(() => deletePaymentMethodApi.loading.value);
  const deletePaymentMethod = (paymentSourceId: string) => deletePaymentMethodApi.execute({ paymentSourceId });

  const cardComponent = ref<InstanceType<typeof CardComponent> | null>(null);
  const paymentSection = ref<HTMLElement | null>(null);

  const {
    checkoutForm,
    checkout$,
    variantForm,
    variant$,
    checkboxesForm,
    checkboxes$,
  } = useForms(isBillingAddressSame, isNewPaymentMethod);

  // Prefill address
  watch(profileUser, (user) => {
    checkoutForm.value.shippingAddress.firstName = user?.firstName ?? '';
    checkoutForm.value.shippingAddress.lastName = user?.lastName ?? '';
    checkoutForm.value.shippingAddress.phone = profileShippingAddress.value?.phone ?? '';
    checkoutForm.value.shippingAddress.line1 = profileShippingAddress.value?.addressLine1 ?? '';
    checkoutForm.value.shippingAddress.line2 = profileShippingAddress.value?.addressLine2 ?? '';
    checkoutForm.value.shippingAddress.city = profileShippingAddress.value?.locality ?? '';
    checkoutForm.value.shippingAddress.zip = profileShippingAddress.value?.postalCode ?? '';
    checkoutForm.value.shippingAddress.state_code = profileShippingAddress.value?.administrativeArea ?? '';
  });

  // Add an object for the billing address
  watch(isBillingAddressSame, (isSame) => {
    checkoutForm.value.billingAddress = !isSame ? {
      firstName: '',
      lastName: '',
      phone: '',
      line1: '',
      line2: '',
      city: '',
      zip: '',
      state_code: '',
    } : null;

    if (!checkoutForm.value.billingAddress) {
      return;
    }

    checkoutForm.value.billingAddress.firstName = profileUser.value?.firstName ?? '';
    checkoutForm.value.billingAddress.lastName = profileUser.value?.lastName ?? '';
    checkoutForm.value.billingAddress.phone = profileBillingAddress.value?.phone ?? '';
    checkoutForm.value.billingAddress.line1 = profileBillingAddress.value?.addressLine1 ?? '';
    checkoutForm.value.billingAddress.line2 = profileBillingAddress.value?.addressLine2 ?? '';
    checkoutForm.value.billingAddress.city = profileBillingAddress.value?.locality ?? '';
    checkoutForm.value.billingAddress.zip = profileBillingAddress.value?.postalCode ?? '';
    checkoutForm.value.billingAddress.state_code = profileBillingAddress.value?.administrativeArea ?? '';
  }, {
    immediate: true,
  });

  // Update paymentSourceId and submissionId
  watch([
    activePaymentMethodId,
    () => quizStore.submissionId,
  ], ([
    activePaymentMethodId,
    submissionId,
  ]) => {
    checkoutForm.value.paymentSourceId = activePaymentMethodId;
    checkoutForm.value.submissionId = submissionId!;
  }, { immediate: true });

  const mainVariant = computed(() => {
    return mainProduct.value?.product.variants.find((variant) => {
      return variant.variantType === VARIANT_TYPE.SUBSCRIPTION
        && variant.displayInQuiz
        && variant.variantPeriod
        && variant.variantPeriod === variantForm.value.period.value
        && variant.variantPeriodUnit === variantForm.value.period.unit;
    });
  });

  const membershipVariant = computed(() => {
    return membershipProduct.value?.product.variants.find((variant) => {
      return variant.variantType === VARIANT_TYPE.SINGLE
          && variant.displayInQuiz;
    });
  });

  const cartItems = computed(() => {
    if (!mainProduct.value) {
      return [];
    }

    return [
      {
        type: 'main',
        productId: mainProduct.value.product.id,
        variantId: mainVariant.value?.id || null,
        variantAmount: mainVariant.value?.variantAmount || 0,
        quantity: 1,
        dosageUnit: mainProduct.value.product.dosageUnit,
        shippingPrice: mainProduct.value.product.shippingPrices[0]?.shippingAmount || 0,
      },
      {
        type: 'membership',
        productId: membershipProduct.value?.product.id as number,
        variantId: membershipVariant.value?.id || null,
        variantAmount: membershipVariant.value?.variantAmount || 0,
        quantity: 1,
        dosageUnit: membershipProduct.value?.product.dosageUnit || undefined,
        shippingPrice: membershipProduct.value?.product.shippingPrices[0]?.shippingAmount || 0,
      },
    ].filter((item) => item.productId && item.variantId);
  });

  const cartPayloadItems = computed<CartItemPayload[]>(() => {
    return cartItems.value.map((item) => omit(item, ['type', 'variantAmount', 'shippingPrice']));
  });

  watch(cartPayloadItems, (items) => {
    checkoutForm.value.items = items;
  });

  const shippingPrice = computed(() => {
    return cartItems.value.reduce((result, item) => {
      return result + item.shippingPrice;
    }, 0);
  });

  const totalPrice = computed(() => {
    return cartItems.value
      .filter((item) => item.type !== 'membership')
      .reduce((result, item) => {
        return item.variantAmount * item.quantity + result;
      }, shippingPrice.value);
  });

  const nowPrice = computed(() => membershipVariant.value?.variantAmount || 0);

  // Prepared checkout form for sending to the server
  const payloadCheckoutForm = computed(() => {
    const form = cloneDeep(checkoutForm.value);
    set(form, 'shippingAddress.phone', form.shippingAddress.phone.replace(/[^\d+]/g, ''));
    if (!isBillingAddressSame.value) {
      set(form, 'billingAddress.phone', form.billingAddress?.phone.replace(/[^\d+]/g, ''));
    }
    return form;
  });

  // Coupon
  const couponInput = ref('');
  const estimateApi = useFetch(apiCart.estimate);
  const estimateData = computed(() => estimateApi.response.value?.data);
  const estimateLoading = computed(() => estimateApi.loading.value);
  const estimateError = computed(() => estimateApi.error.value);

  const applyCoupon = async () => {
    if (!couponInput.value) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('coupon') === null) return;
      couponInput.value = searchParams.get('coupon') ?? '';
    }
    if (estimateLoading.value) return;

    await estimateApi.execute({
      // ...omit(payloadCheckoutForm.value, ['tokenId', 'paymentSourceId']),
      ...payloadCheckoutForm.value,
      coupon: couponInput.value,
    });

    if (estimateError.value) {
      return;
    }

    checkoutForm.value.coupon = couponInput.value;
    couponInput.value = '';
  };

  const removeCoupon = () => {
    checkoutForm.value.coupon = '';
    couponInput.value = '';
  };

  const discountPrice = computed(() => {
    const discount = sumBy(estimateData.value?.estimation.next_invoice_estimate?.discounts, 'amount');
    return totalPrice.value - discount;
  });

  const nowDiscountPrice = computed(() => {
    const discountNow = sumBy(estimateData.value?.estimation.invoice_estimate?.discounts, 'amount');

    return nowPrice.value - discountNow;
  });

  // Buy now
  const buyNowApi = useFetch(apiCart.buyNow);
  const buyNowLoading = ref(false);

  const scrollToError = async () => {
    await nextTick();
    const errorSection = document.querySelector('.validation-error');
    errorSection?.scrollIntoView({ behavior: 'smooth' });
  };

  const validateNewPayment = () => new Promise((resolve, reject) => {
    if (!isNewPaymentMethod.value) {
      resolve(true);
      return;
    }
    cardComponent.value?.tokenize()
      .then((data: { token: string }) => {
        checkoutForm.value.tokenId = data.token;
        resolve(true);
      })
      .catch((e: string) => reject(e));
  });

  const buyNow = async () => {
    if (!await variant$.value.$validate()) {
      scrollToError();
      return;
    }

    buyNowLoading.value = true;

    const validation = await Promise.all([
      checkout$.value.paymentSourceId.$validate(),
      validateNewPayment().catch((e: string) => {
        toast.error(e);
        return false;
      }),
      checkout$.value.shippingAddress.$validate(),
      checkout$.value.billingAddress.$validate(),
    ]);

    if (validation.some((v) => !v)) {
      scrollToError();
      buyNowLoading.value = false;
      return;
    }

    try {
      const response = await buyNowApi.execute(payloadCheckoutForm.value);

      const URLSearchParam = new URL(response!.data.url).searchParams;
      quizStore.paymentState = URLSearchParam.get('payment-state');
      quizStore.checkoutId = URLSearchParam.get('id');

      await quizStore.getQuiz();
      quizStore.paid = true;

      const checkoutId = quizStore.checkoutId || crypto.randomUUID();

      const cartResponse = await apiCart.get();
      const cart = cartResponse.data;

      if (!cart) {
        return;
      }

      // Refresh profile store and re-init analytics with new data
      await userStore.refreshUserProfile();
      analyticsStore.initAnalytics();

      analyticsStore.purchase(
        cartStore.toAnalyticProducts(cart.items),
        checkoutId,
        cart.total / 100,
        cart.id,
      );
    } finally {
      buyNowLoading.value = false;
    }
  };

  return {
    products,
    mainProduct,
    membershipProduct,
    checkoutForm,
    isBillingAddressSame,
    checkout$,
    variantForm,
    variant$,
    checkboxesForm,
    checkboxes$,
    couponInput,
    applyCoupon,
    removeCoupon,
    estimateLoading,
    discountPrice,
    totalPrice,
    nowPrice,
    nowDiscountPrice,
    cartItems,
    buyNow,
    buyNowLoading,
    cardComponent,
    paymentSection,
    paymentMethods,
    isNewPaymentMethod,
    activePaymentMethodId,
    deletePaymentMethod,
  };
});
