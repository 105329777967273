<script setup lang="ts">
import PromoCode from '@components/PromoCode/PromoCode.vue';
import { QUIZ_TYPE, type StepCheckout } from '@models/quiz';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { useQuizStore } from '@stores/quiz';
import { computed, onMounted, ref, watch } from 'vue';
import CheckoutInfo from './components/CheckoutInfo/CheckoutInfo.vue';
import CheckoutPromo from './components/CheckoutPromo/CheckoutPromo.vue';
import CheckoutReviews from './components/CheckoutReviews/CheckoutReviews.vue';
import CheckoutWelcome from './components/CheckoutWelcome/CheckoutWelcome.vue';
import WeightLossCart from './components/WeightLossCart/WeightLossCart.vue';
import { PRODUCT_TYPE } from '@/js/models/product';
import { useAnalyticsStore } from '@/js/stores';

const props = defineProps<{
  content: StepCheckout;
}>();

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

const checkoutStore = useCheckoutStore();
const quizStore = useQuizStore();
const analyticsStore = useAnalyticsStore();

const {
  topBannerText,
  topBannerPromocode,
} = quizStore;

const cartComponent = computed(() => {
  if ([
    QUIZ_TYPE.QUIZ_ERECTILE_DYSFUNCTION,
    QUIZ_TYPE.QUIZ_EARLY_CLIMAX,
  ].includes(quizStore.quizType!)) {
    return WeightLossCart;
  }

  return WeightLossCart;
});

const pages = [
  CheckoutWelcome,
  CheckoutPromo,
  CheckoutInfo,
  CheckoutReviews,
  cartComponent.value,
];

const activePageIndex = ref(0);
const activePage = computed(() => pages[activePageIndex.value]);

const next = () => {
  if (activePageIndex.value === pages.length - 1) {
    emit('next');
    return;
  }
  activePageIndex.value += 1;
};

const back = () => {
  if (activePageIndex.value === 0) {
    emit('back');
    return;
  }
  activePageIndex.value -= 1;
};

// Set store products
checkoutStore.products = props.content.products;

// Set the store's main product
checkoutStore.mainProduct = props.content.products
  .find((product) => product.product.id === quizStore.prefillProductId)
    || props.content.products[0];

// Filter the selected product and membership
const filteredProducts = computed(() => {
  return props.content.products.filter((p) => {
    return p.product.id !== checkoutStore.mainProduct?.product.id
      && !p.product.productType.includes(PRODUCT_TYPE.ADD_ON)
      && !p.product.productType.includes(PRODUCT_TYPE.DIGITAL);
  });
});

const isPromoCode = ref(false);

onMounted(() => {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get('newyear') !== null) {
    isPromoCode.value = false; // todo: set to true when promo code input field will work
  }
});

watch(activePage, () => {
  analyticsStore.viewQuizStep(
    `CHK-${activePageIndex.value}`,
    activePage.value.__name || `CHK-${activePageIndex.value}`,
    activePage.value.__name || `CHK-${activePageIndex.value}`,
  );
});
</script>

<template>
  <Teleport to="body">
    <Transition name="slide-animation">
      <PromoCode
        v-if="isPromoCode && topBannerText && topBannerPromocode"
        :text="topBannerText"
        :code="topBannerPromocode"
      />
    </Transition>
  </Teleport>
  <div class="px-20 text-black lg:px-40">
    <Transition
      name="fade"
      mode="out-in"
    >
      <component
        :is="activePage"
        :content="content"
        :product-list="filteredProducts"
        @next="next"
        @back="back"
      />
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
// Fade animation
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<style>
.checkout-slide-left {
  animation: slide-left 0.5s both;
}

@keyframes slide-left {
  from {
    transform: translateX(120px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
