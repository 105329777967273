<script setup lang="ts">
import { onMounted } from 'vue';
import imgPhone from '../../assets/img/checkout-welcome-phone.webp';
import imgTirzepatide from '../../assets/img/checkout-welcome-tirzepatide.webp';
import videoDoctor from '../../assets/video/checkout-welcome-doctor.mp4';

const emit = defineEmits<{
  'next': [];
}>();

onMounted(() => {
  setTimeout(() => emit('next'), 3000);
});
</script>

<template>
  <div class="fade fixed left-0 top-0 flex h-full w-full">
    <div class="relative m-auto h-[100px] w-full max-w-[1180px]">
      <figure class="slide-left-1 absolute right-[-24%] top-[-199%] w-[84.2%] overflow-hidden rounded-[10px] shadow md:right-[-3%] md:top-[-309%] md:w-[46.9%]">
        <video
          class="w-full"
          muted
          loop
          autoplay
          playsinline
        >
          <source
            :src="videoDoctor"
            type="video/mp4"
          >
        </video>
      </figure>

      <figure class="slide-left-2 absolute left-[-25%] top-[-61%] w-[55.2%] overflow-hidden rounded-[10px] shadow md:left-[3.1%] md:top-[-201%] md:w-[30.8%]">
        <img
          class="w-full"
          :src="imgTirzepatide"
          alt=""
        >
      </figure>

      <figure class="slide-left-3 absolute left-[22%] top-[109%] z-[-1] w-[48%] overflow-hidden rounded-[10px] shadow md:left-[33.5%] md:top-[89%] md:w-[26.5%]">
        <img
          class="w-full"
          :src="imgPhone"
          alt=""
        >
      </figure>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shadow {
  box-shadow: 0px -2.86px 7.18px -2px #8B878407,
    0px -0.89px 9.91px -2px #8B87840B,
    0px 5.55px 11.88px -2px #8B87840D,
    0px 16.09px 16.76px -2px #8B878410,
    0px 30.36px 28.24px -2px #8B878413,
    0px 48px 50px -2px #8B87841A;
}

// Fade animation
.fade {
  animation: fade 5s linear both;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Slide left 1 animation
.slide-left-1 {
  animation: slide-1 5s linear both;
}

@keyframes slide-1 {
  from {
    transform: translateX(18%);
  }
  to {
    transform: translateX(-18%);
  }
}

// Slide left 2 animation
.slide-left-2 {
  animation: slide-2 5s linear both;
}

@keyframes slide-2 {
  from {
    transform: translateX(22%);
  }
  to {
    transform: translateX(-22%);
  }
}

// Slide left 3 animation
.slide-left-3 {
  animation: slide-3 5s linear both;
}

@keyframes slide-3 {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(-10%);
  }
}
</style>
