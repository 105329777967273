import { apiCart } from '@api/cart';
import { formatUnitAmount } from '@helpers/tools';
import { useFetch } from '@helpers/use-fetch';
import { Cart, CartItem, CartUpdatePayload } from '@models/cart';
import { Product } from '@models/product';
import { VARIANT_TYPE } from '@models/variant';
import { AnalyticProduct, useAnalyticsStore } from '@stores/analytics';
import { sortBy } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';

const ITEMS_LIMIT = 100;

export const useCartStore = defineStore('cart', () => {
  const cart = ref<Cart|null>(null);
  const couponInput = ref<string|null>(null);
  const addItemLoadingMap = ref<Map<number, boolean>>(new Map<number, boolean>());
  const toggleTypeItemLoadingMap = ref<Map<string, boolean>>(new Map<string, boolean>());

  const analyticsStore = useAnalyticsStore();

  const cartApi = useFetch(apiCart.get);
  const cartUpdateApi = useFetch(apiCart.update);
  const cartUpdateItemApi = useFetch(apiCart.updateItem);
  const cartRemoveItemApi = useFetch(apiCart.removeItem);
  const cartRemoveAllApi = useFetch(apiCart.removeAll);
  const createPaymentApi = useFetch(apiCart.createPayment);
  const buyNowApi = useFetch(apiCart.buyNow);
  const applyCouponApi = useFetch(apiCart.applyCoupon);
  const toggleTypeItemApi = useFetch(apiCart.toggleTypeItem);
  const confirmPaymentApi = useFetch(apiCart.confirmPayment);

  const cartLoading = computed(() => cartApi.loading.value);
  const cartUpdateLoading = computed(() => cartUpdateApi.loading.value);
  const createPaymentLoading = computed(() => createPaymentApi.loading.value);
  const applyCouponLoading = computed(() => applyCouponApi.loading.value);
  const confirmPaymentLoading = computed(() => confirmPaymentApi.loading.value);
  const cartRemoveAllLoading = computed(() => cartRemoveAllApi.loading.value);

  const createPaymentData = computed(() => createPaymentApi.response.value?.data);
  const createPaymentError = computed(() => createPaymentApi.error.value);

  const hasInjectionProduct = computed(() => !!cart.value?.items.find((cartItem) => cartItem.product?.injectionAck));

  const cartUpdateItemData = computed(() => cartUpdateItemApi.response.value?.data ?? null);
  const cartRemoveItemData = computed(() => cartRemoveItemApi.response.value?.data ?? null);
  const cartRemoveAllData = computed(() => cartRemoveAllApi.response.value?.data ?? null);
  const cartUpdateData = computed(() => cartUpdateApi.response.value?.data ?? null);
  const cartData = computed(() => cartApi.response.value?.data ?? null);
  const toggleTypeItemData = computed(() => toggleTypeItemApi.response.value?.data ?? null);

  const toggleTypeItemError = computed(() => toggleTypeItemApi.error.value ?? null);
  const cartUpdateItemError = computed(() => cartUpdateItemApi.error.value ?? null);
  const cartRemoveItemError = computed(() => cartRemoveItemApi.error.value ?? null);
  const cartRemoveAllError = computed(() => cartRemoveAllApi.error.value ?? null);
  const cartUpdateError = computed(() => cartUpdateApi.error.value ?? null);
  const cartError = computed(() => cartApi.error.value ?? null);
  const confirmPaymentError = computed(() => confirmPaymentApi.error.value ?? null);

  const analyticItems = computed((): AnalyticProduct[] => cart.value?.items.map((item) => ({
    idp: item.productId,
    idv: item.variantId,
    item_name: `${item.product?.title} ${item.variant?.title}`,
    price: (item.total || 0) / 100,
    quantity: item.quantity,
  })) || []);
  const analyticTotal = computed(() => (cart.value?.total || 0) / 100);

  watch(cartData, () => {
    if (cartError.value) {
      return;
    }

    cart.value = cartData.value;
  });

  watch(cartUpdateData, () => {
    if (cartUpdateError.value) {
      return;
    }

    cart.value = cartUpdateData.value;
  });

  watch(cartUpdateItemData, () => {
    if (cartUpdateItemError.value) {
      return;
    }

    cart.value = cartUpdateItemData.value;
  });

  watch(toggleTypeItemData, () => {
    if (toggleTypeItemError.value) {
      return;
    }

    cart.value = toggleTypeItemData.value;
  });

  watch(cartRemoveItemData, () => {
    if (cartRemoveItemError.value) {
      return;
    }

    cart.value = cartRemoveItemData.value;
  });

  watch(cartRemoveAllData, () => {
    if (cartRemoveAllError.value) {
      return;
    }

    cart.value = cartRemoveAllData.value;
  });

  const formattedCartSubtotal = computed(() => formatUnitAmount((cart.value?.subtotal || 0) / 100, cart.value?.currency));
  const formattedCartTotal = computed(() => formatUnitAmount((cart.value?.total || 0) / 100, cart.value?.currency));
  const formattedCartDiscount = computed(() => `-${formatUnitAmount((cart.value?.discount || 0) / 100, cart.value?.currency)}`);
  const formattedCartShipping = computed(() => formatUnitAmount((cart.value?.shipping || 0) / 100, cart.value?.currency));

  const withSubscription = computed(() => !!cart.value?.items.find((cartItem) => cartItem.variant?.variantType === VARIANT_TYPE.SUBSCRIPTION));
  const withSubscriptionAddon = computed(() => !!cart.value?.items.find((cartItem) => cartItem.variant?.variantType === VARIANT_TYPE.SUBSCRIPTION_ADDON));
  const selectedVariantPeriod = computed(() => {
    const cartItem = cart.value?.items.find((cartItem) => {
      if (!cartItem.variant) {
        return null;
      }

      return [
        VARIANT_TYPE.SUBSCRIPTION,
        VARIANT_TYPE.SUBSCRIPTION_ADDON,
      ].includes(cartItem.variant?.variantType);
    });
    return cartItem?.variant?.variantPeriod ?? null;
  });
  const cartItemSubscription = computed(() => cart.value?.items.find((item) => item.variant?.variantType === VARIANT_TYPE.SUBSCRIPTION));

  const cartItems = computed(() => sortBy(cart.value?.items || [], (item) => {
    if (item.variant?.variantType === VARIANT_TYPE.SUBSCRIPTION) {
      return 1;
    }

    if (item.variant?.variantType === VARIANT_TYPE.SUBSCRIPTION_ADDON) {
      return 2;
    }

    if (item.variant?.variantType === VARIANT_TYPE.SINGLE) {
      return 3;
    }

    return null;
  }));

  const findItem = (variantId: number) => {
    return cart.value?.items.find((item) => item.variantId === variantId) || null;
  };

  const update = async (payload: CartUpdatePayload) => {
    await cartUpdateApi.execute(payload);
  };

  const updateItem = async (productId: number, variantId: number, params: { increase: boolean } = { increase: true }) => {
    const eventId = crypto.randomUUID();

    try {
      addItemLoadingMap.value.set(variantId, true);

      const cartItem = findItem(variantId);

      if (null === cartItem) {
        await cartUpdateItemApi.execute({
          variantId,
          productId,
          quantity: 1,
          eventId,
        });

        return;
      }

      if (!params.increase && cartItem.quantity - 1 <= 0) {
        await cartRemoveItemApi.execute({
          id: cartItem.id,
          eventId,
        });

        return;
      }

      if (cartItem.quantity <= ITEMS_LIMIT) {
        await cartUpdateItemApi.execute({
          id: cartItem.id,
          variantId: cartItem.variantId,
          productId: cartItem.productId,
          quantity: params.increase ? cartItem.quantity + 1 : cartItem.quantity - 1,
          eventId,
        });
      }
    } finally {
      addItemLoadingMap.value.set(variantId, false);

      const cartItem = findItem(variantId);
      if (cartItem) {
        if (params.increase) {
          analyticsStore.addToCart(
            toAnalyticProducts([cartItem]),
            (cart.value?.total || 0) / 100,
            eventId,
          );
        } else {
          analyticsStore.removeFromCart(
            toAnalyticProducts([cartItem]),
            (cart.value?.total || 0) / 100,
            eventId,
          );
        }
      }
    }
  };

  const toggleTypeItem = async (priceId: string) => {
    try {
      toggleTypeItemLoadingMap.value.set(priceId, true);

      await toggleTypeItemApi.execute({ price: priceId });
    } finally {
      toggleTypeItemLoadingMap.value.set(priceId, false);
    }
  };

  const createPayment = async () => {
    const result = await createPaymentApi.execute({
      eventId: crypto.randomUUID(),
    });
    if (result?.data.url) {
      location.href = result?.data.url;
    }
  };

  const getCart = async () => {
    await cartApi.execute({});
  };

  const applyCoupon = async () => {
    if (!couponInput.value) {
      return;
    }

    await applyCouponApi.execute({ coupon: couponInput.value });
    await getCart();
  };

  const confirmPayment = async () => {
    await confirmPaymentApi.execute({});
  };

  const removeAll = async () => {
    await cartRemoveAllApi.execute({});
  };

  const totals = computed(() => [
    {
      label: 'Subtotal',
      value: formattedCartSubtotal.value,
    },
    {
      label: 'Discount',
      value: formattedCartDiscount.value,
      hideZero: true,
    },
    {
      label: 'Shipping',
      value: formattedCartShipping.value,
    },
    {
      label: 'Total',
      value: formattedCartTotal.value,
      isMain: true,
    },
  ]);

  const totalsSummary = computed(() => [
    {
      label: 'Subtotal',
      value: formattedCartSubtotal.value,
    },
    {
      label: 'Discount',
      value: formattedCartDiscount.value,
      hideZero: true,
    },
    {
      label: 'Shipping',
      value: formattedCartShipping.value,
    },
  ]);

  const toAnalyticProducts = (cartItems: CartItem[]): AnalyticProduct[] => {
    return cartItems.map((item, index) => {
      const price = item.amount >= 0 ? (item.amount / 100) : 0;
      return {
        item_name: `${item.product?.title} - ${item.variant?.variantCustomTitle || item.variant?.title || ''}`,
        price: Number(price.toFixed(2)),
        quantity: item.quantity,
        idp: item.productId,
        idv: item.variantId,
        item_id: String(item.product?.id),
        item_brand: 'JRNYS',
        item_category: item.product?.quizCategory.slug,
        item_list_id: item.product?.quizCategory.slug,
        item_list_name: item.product?.quizCategory.title,
        index,
      };
    });
  };

  const productsToAnalyticProducts = (products: Product[], quantity: number = 999): AnalyticProduct[] => {
    return products.map((product, index) => {
      const firstVariant = product?.variants?.length ? product.variants[0] : null;
      return {
        item_name: `${product.title} - ${firstVariant?.variantCustomTitle || firstVariant?.title || ''}`,
        price: (firstVariant?.variantAmount ?? 0) / 100,
        quantity: quantity,
        idp: product.id,
        idv: firstVariant?.id || 0,
        item_id: String(product.id),
        item_brand: 'JRNYS',
        item_category: product.quizCategory.slug,
        item_list_id: product.quizCategory.slug,
        item_list_name: product.quizCategory.title,
        index,
      };
    });
  };

  const openPharmacy = () => {
    location.href = '/patient/pharmacy';
  };

  return {
    createPayment,
    buyNowApi,
    getCart,
    updateItem,
    toggleTypeItem,
    applyCoupon,
    confirmPayment,
    removeAll,
    update,
    cartUpdateApi,
    createPaymentData,
    createPaymentError,
    cart,
    analyticItems,
    analyticTotal,
    formattedCartSubtotal,
    formattedCartTotal,
    formattedCartDiscount,
    formattedCartShipping,
    couponInput,
    cartLoading,
    cartUpdateLoading,
    createPaymentLoading,
    applyCouponLoading,
    confirmPaymentLoading,
    cartRemoveAllLoading,
    confirmPaymentError,
    addItemLoadingMap,
    toggleTypeItemLoadingMap,
    toggleTypeItemError,
    hasInjectionProduct,
    totals,
    totalsSummary,
    withSubscription,
    withSubscriptionAddon,
    selectedVariantPeriod,
    cartItems,
    cartItemSubscription,
    toAnalyticProducts,
    productsToAnalyticProducts,
    openPharmacy,
  };
});
