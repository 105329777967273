<script setup lang="ts">
import ChevronRight from '@assets/icons/chevron-right.svg?component';
import { BaseDialog, BaseButton } from '@components/base';
import BaseDialogV2 from '@components/quiz_v2/BaseDialog/BaseDialog.vue';
import { MODAL_TYPE } from '@models/modals';
import { UserLoginPayload } from '@models/user';
import { useUserStore } from '@stores/index';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { ref, reactive, computed, watch, nextTick } from 'vue';

type Props = {
  visibility?: boolean,
  entrySectionHandle?: string,
};

const props = defineProps<Props>();
const emit = defineEmits<{
  'update:visibility': [value: boolean],
  success: []
}>();
const userStore = useUserStore();
const inputRef = ref<HTMLElement | null>(null);

const formData = reactive<UserLoginPayload>({
  loginName: '',
  password: '',
});

const rules = {
  loginName: { email, required },
  password: { required, minValue: minLength(6) },
};

const v$ = useVuelidate(rules, formData);

const model = computed({
  get() {
    return props.visibility;
  },
  set(value) {
    emit('update:visibility', value);
  },
});

const dialogComponent = computed(() => {
  return props.entrySectionHandle === 'campaigns' ? BaseDialogV2 : BaseDialog;
});

const onSubmit = async () => {
  const isValid = await v$.value.$validate();

  if (!isValid) {
    return;
  }

  const result = await userStore.login(formData);

  if (result) {
    emit('success');
  }
};

watch(() => props.visibility, async (visibility) => {
  if (!visibility) {
    return;
  }
  await nextTick();
  inputRef.value?.focus();
});
</script>

<template>
  <component
    :is="dialogComponent"
    v-model:visibility="model"
    class="login-modal"
    data-testid="login-modal"
  >
    <form @submit.prevent="onSubmit">
      <div
        class="text-37 font-light"
        data-testid="login-modal-header"
      >
        Login to your account
      </div>

      <div class="mt-40 flex flex-col gap-16">
        <input
          ref="inputRef"
          v-model.trim="formData.loginName"
          class="input input--no-shadow"
          :class="{ 'input--error': v$.loginName.$error }"
          type="text"
          placeholder="Email Address"
          data-testid="login-email-input"
        >
        <p
          v-for="error in v$.loginName.$errors"
          :key="error.$uid"
          class="text-red"
          data-testid="login-email-error-message"
        >
          {{ error.$message }}
        </p>

        <input
          v-model="formData.password"
          class="input input--no-shadow"
          :class="{ 'input--error': v$.password.$error }"
          type="password"
          placeholder="Password"
          data-testid="login-password-input"
        >
        <p
          v-for="error in v$.password.$errors"
          :key="error.$uid"
          class="text-red"
          data-testid="login-password-error-message"
        >
          {{ error.$message }}
        </p>

        <div class="mt-16 flex flex-col items-center justify-center gap-16 sm:mt-0 sm:flex-row">
          <button
            class="text-18 font-bold text-turquoise hover:text-white"
            type="button"
            data-testid="login-forgot-password-button"
            @click="$root.setModal(MODAL_TYPE.RESET_PASSWORD, true)"
          >
            Forgot your password?
          </button>

          <BaseButton
            class="min-w-[152px] bg-turquoise text-white hover:bg-white hover:text-deep-blue"
            text="Login"
            type="submit"
            :loading="userStore.loading"
            :disabled="v$.$error"
            animated
            data-testid="login-submit-button"
          >
            <template #icon-right>
              <ChevronRight />
            </template>
          </BaseButton>
        </div>
      </div>
    </form>
  </component>
</template>

<style lang="scss" scoped>
.login-modal {
  :deep {
    .base-dialog__content {
      background-color: var(--color-deep-blue);
      color: var(--color-white);

      @include up('lg') {
        max-width: 700px;
        padding: 70px 120px;
      }
    }
  }
}
</style>
