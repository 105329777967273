<script setup lang="ts">
import { useFloating, flip, offset, size as floatingSize } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { computed, ref, useSlots } from 'vue';
import IconChevronDown from './assets/chevron-down.svg';
import type { BaseSelectOption } from './types';

const props = withDefaults(defineProps<{
  modelValue?: string | number | null;
  options?: BaseSelectOption[];
  trackBy?: string;
  optionLabel?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  hint?: string;
  error?: string;
  name?: string;
  id?: string;
  dataTestpl?: string;
}>(), {
  modelValue: undefined,
  options: () => [],
  trackBy: 'value',
  optionLabel: 'label',
  placeholder: '',
  label: '',
  disabled: false,
  hint: '',
  error: '',
  name: '',
  id: undefined,
  dataTestpl: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: string | number];
}>();

const slots = useSlots();
const input = ref<HTMLInputElement | null>(null);

const reference = ref<HTMLElement | null>(null);
const floating = ref<HTMLElement | null>(null);
const isOpen = ref(false);
const isFocused = ref(false);

const { floatingStyles } = useFloating(reference, floating, {
  placement: 'bottom-start',
  middleware: [
    offset(8),
    flip(),
    floatingSize({
      apply({ rects, elements, availableHeight }) {
        Object.assign(elements.floating.style, {
          minWidth: `${rects.reference.width}px`,
          maxHeight: `${availableHeight - 8}px`,
        });
      },
    }),
  ],
});

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value!);
  },
});

const activeOption = computed(() => {
  return props.options.find((option) => option[props.trackBy] === model.value) ?? {};
});

const toggleOpen = () => {
  if (props.disabled) {
    return;
  }
  isOpen.value = !isOpen.value;
};

onClickOutside(reference, () => isOpen.value = false);

const appearanceClass = computed(() => {
  if (props.disabled) {
    return 'opacity-50 outline-transparent';
  }
  if (props.error) {
    return 'outline-error-500';
  }
  if (isFocused.value) {
    return 'outline-primary-500';
  }
  return 'outline-transparent';
});
</script>

<template>
  <div>
    <p
      v-if="label"
      class="mb-10 text-12 font-medium uppercase"
    >
      {{ label }}
    </p>

    <div ref="reference">
      <label
        class="relative
            flex
            h-[49px]
            w-full
            gap-12
            rounded-[5px]
            border
            border-primary-200
            bg-white
            px-16
            text-16
            text-black
            outline
            outline-2
            -outline-offset-1
            transition-all
            duration-200"
        :class="[
          appearanceClass,
          {
            'cursor-pointer': !disabled,
          },
        ]"
        @click.prevent="toggleOpen"
        @keydown.space.prevent="toggleOpen"
        @keyup.enter="toggleOpen"
      >
        <!-- Left slot for icon or dropdown -->
        <span
          v-if="slots.left"
          class="flex shrink-0 items-center"
        >
          <slot name="left" />
        </span>

        <input
          :id="id"
          ref="input"
          :value="activeOption[optionLabel]"
          :data-testpl="dataTestpl"
          class="h-full
              w-full
              bg-transparent
              outline-none
              placeholder:text-primary-300"
          :placeholder="placeholder"
          type="text"
          :name="name"
          :disabled="disabled"
          readonly
          @focus="isFocused = true"
          @blur="isFocused = false"
        >

        <IconChevronDown class="pointer-events-none shrink-0 self-center" />

        <!-- Right slot for icon or dropdown -->
        <span
          v-if="slots.right"
          class="flex shrink-0 items-center"
        >
          <slot name="right" />
        </span>
      </label>

      <Teleport to="body">
        <div
          v-if="isOpen"
          ref="floating"
          class="overflow-auto rounded-[5px] bg-white py-8 shadow"
          :style="floatingStyles"
        >
          <ul class="flex flex-col">
            <li
              v-for="option in options"
              :key="option[trackBy]"
            >
              <button
                class="w-full whitespace-nowrap px-16 py-8 text-left hover:bg-primary-100"
                :class="{
                  '!bg-primary-500 text-white': option[trackBy] === modelValue,
                }"
                type="button"
                data-testpl="base-select-option"
                @click="model = option[trackBy]"
              >
                {{ option[optionLabel] }}
              </button>
            </li>
          </ul>
        </div>
      </Teleport>
    </div>

    <p
      v-if="error"
      class="mt-8 text-14 text-error-500"
    >
      {{ error }}
    </p>
    <p
      v-else-if="hint"
      class="mt-8 text-14 text-deep-blue-100"
    >
      {{ hint }}
    </p>
  </div>
</template>
