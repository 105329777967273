<script setup lang="ts">
import BaseButton from '@components/quiz_v2//BaseButton/BaseButton.vue';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { storeToRefs } from 'pinia';
import IconArrowLeft from '../../assets/icons/arrow-left.svg';
import IconArrowRight from '../../assets/icons/arrow-right.svg';
import imgDoctors from '../../assets/img/checkout-info-doctors.webp';

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

const checkoutStore = useCheckoutStore();
const { mainProduct } = storeToRefs(checkoutStore);
</script>

<template>
  <div class="flex flex-col items-center overflow-hidden">
    <div class="checkout-slide-left flex w-full max-w-[530px] flex-col">
      <h1 class="text-28 font-medium text-primary-400">
        You’re in good hands
      </h1>

      <figure class="mt-32 md:mt-36">
        <img
          class="rounded-[5px]"
          :src="imgDoctors"
          alt=""
        >
      </figure>

      <p class="mt-32 text-16 md:mt-24">
        Before you’re prescribed, a licensed provider will assess your health history to ensure your medication selection is a good fit for you.
      </p>

      <div class="mt-40 grid items-center gap-x-20 gap-y-16 md:grid-cols-[185px,1fr]">
        <BaseButton
          color="black"
          data-testpl="checkout-info-continue-btn"
          animated
          @click="emit('next')"
        >
          Continue with {{ mainProduct?.product.title }}

          <template #icon-right>
            <IconArrowRight />
          </template>
        </BaseButton>

        <BaseButton
          class="md:order-first"
          theme="outlined"
          data-testpl="checkout-info-back-btn"
          animated
          @click="emit('back')"
        >
          Back

          <template #icon-left>
            <IconArrowLeft />
          </template>
        </BaseButton>
      </div>
    </div>
  </div>
</template>
