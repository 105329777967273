<script setup lang="ts">
import BaseSpinner from '@components/quiz_v2/BaseSpinner/BaseSpinner.vue';
import { formatUnitAmountCents } from '@helpers/tools';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const checkoutStore = useCheckoutStore();

const {
  checkoutForm,
  totalPrice,
  nowPrice,
  discountPrice,
  nowDiscountPrice,
  variantForm,
  estimateLoading,
} = storeToRefs(checkoutStore);

const monthCount = computed(() => {
  const monthsPerUnit = { day: 0.033, week: 0.25, month: 1, year: 12 };
  if (!variantForm.value) return 1;
  return monthsPerUnit[variantForm.value.period.unit!] * variantForm.value.period.value!;
});

const monthlyPrice = computed(() => totalPrice.value / monthCount.value);
const monthlyDiscountPrice = computed(() => discountPrice.value / monthCount.value);
</script>

<template>
  <div>
    <div class="flex justify-between gap-16 text-16">
      <p>Monthly price</p>

      <template v-if="estimateLoading">
        <BaseSpinner />
      </template>
      <template v-else>
        <div
          v-if="checkoutForm.coupon && monthlyPrice != monthlyDiscountPrice"
          class="flex gap-36"
        >
          <p class="line-through opacity-50">
            {{ formatUnitAmountCents(monthlyPrice) }}
          </p>
          <p>{{ formatUnitAmountCents(monthlyDiscountPrice) }}</p>
        </div>

        <p v-else>
          {{ formatUnitAmountCents(monthlyPrice) }}
        </p>
      </template>
    </div>

    <div class="mt-8 flex justify-between gap-16 text-16">
      <p>Total if prescribed</p>

      <template v-if="estimateLoading">
        <BaseSpinner />
      </template>
      <template v-else>
        <div
          v-if="checkoutForm.coupon && totalPrice != discountPrice"
          class="flex gap-36"
        >
          <p class="line-through opacity-50">
            {{ formatUnitAmountCents(totalPrice) }}
          </p>
          <p>{{ formatUnitAmountCents(discountPrice) }}</p>
        </div>

        <p v-else>
          {{ formatUnitAmountCents(totalPrice) }}
        </p>
      </template>
    </div>

    <div class="mt-20 flex justify-between gap-16 text-18 font-bold">
      <p>Due now</p>

      <template v-if="estimateLoading">
        <BaseSpinner />
      </template>
      <template v-else>
        <div
          v-if="checkoutForm.coupon && nowPrice != nowDiscountPrice"
          class="flex gap-36"
        >
          <p class="line-through opacity-50">
            {{ formatUnitAmountCents(nowPrice) }}
          </p>
          <p>{{ formatUnitAmountCents(nowDiscountPrice) }}</p>
        </div>
        <p v-else>
          {{ formatUnitAmountCents(nowPrice) }}
        </p>
      </template>
    </div>

    <div class="mt-32 border-t border-primary-200 pt-32 text-12">
      <p>
        After this, a provider will review your information to determine if treatment is right for you.
      </p>
      <p class="mt-20">
        All multi month plans begin at starting doses
      </p>
    </div>
  </div>
</template>
