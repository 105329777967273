<script setup lang="ts">
import BaseButton from '@components/quiz_v2//BaseButton/BaseButton.vue';
import { StepCheckout } from '@models/quiz';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { register } from 'swiper/element/bundle';
import { computed, onMounted, ref } from 'vue';
import IconArrowDown from '../../assets/icons/arrow-down.svg';
import IconArrowLeft from '../../assets/icons/arrow-left.svg';
import IconArrowRight from '../../assets/icons/arrow-right.svg';

const props = defineProps<{
  content: StepCheckout;
}>();

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

register();

const breakpoints = useBreakpoints(breakpointsTailwind);
const greaterOrEqualMd = breakpoints.greaterOrEqual('md');

const checkoutStore = useCheckoutStore();
const { mainProduct } = storeToRefs(checkoutStore);

const trustbox = ref<HTMLElement | null>(null);
const filteredReviews = computed(() => props.content.reviews.filter((review) => review.image.url));

onMounted(() => {
  if (!window.Trustpilot) {
    return;
  }
  window.Trustpilot.loadFromElement(trustbox.value!);
});
</script>

<template>
  <div class="-mx-20 flex flex-col items-center overflow-hidden md:mx-0">
    <div class="checkout-slide-left w-full max-w-[530px] px-20 md:px-0">
      <header class="text-primary-400">
        <h1 class="text-28 font-medium">
          Real Weight Loss Stories
        </h1>
        <p class="mt-6 text-16">
          From our 100,000+ users
        </p>
      </header>
    </div>

    <div class="checkout-slide-left mt-28 w-full max-w-[745px] bg-primary-200 pb-30 md:bg-transparent">
      <span class="absolute inset-x-[20px] inset-y-0 rounded-[10px] bg-primary-200" />

      <div class="relative">
        <swiper-container
          class="slider"
          slides-per-view="auto"
          :space-between="24"
          :centered-slides="!greaterOrEqualMd || filteredReviews.length > 3"
          :initial-slide="1"
          :allow-touch-move="false"
          :loop="filteredReviews.length > 3"
          :speed="800"
          :autoplay="{
            delay: 2000,
          }"
          :breakpoints="
            {
              768: {
                slidesPerView: 3,
              },
            }"
        >
          <swiper-slide
            v-for="(review, index) in filteredReviews"
            :key="index"
            class="relative h-auto w-[235px]"
            :class="{
              'opacity-0': greaterOrEqualMd && filteredReviews.length > 3,
            }"
          >
            <img
              v-if="review.image.type?.startsWith('image')"
              class="h-[294px] w-full rounded-[10px] object-cover"
              :src="review.image.url!"
              alt=""
            >
            <video
              v-if="review.image.type?.startsWith('video')"
              class="h-[294px] w-full rounded-[10px] object-cover"
              :src="review.image.url!"
              muted
              loop
              autoplay
              playsinline
            />
            <div
              v-if="review.label"
              class="label absolute inset-x-0 -bottom-36 flex justify-center"
            >
              <div class="flex h-[45px] items-center gap-8 rounded-[10px] bg-white px-30 text-16">
                <IconArrowDown />
                <span>{{ review.label }}</span>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>

      <div
        ref="trustbox"
        class="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="66cf540e321310cee73cb707"
        data-style-height="28px"
        data-style-width="100%"
        data-font-family="Rubik"
      >
        <a
          href="https://www.trustpilot.com/review/jrnys.com"
          target="_blank"
          rel="noopener"
        >
          Trustpilot
        </a>
      </div>
    </div>

    <div class="mt-40 w-full max-w-[530px] px-20 md:mt-60 md:px-0">
      <div class="grid items-center gap-x-20 gap-y-16 md:grid-cols-[185px,1fr]">
        <BaseButton
          color="black"
          data-testpl="checkout-info-continue-btn"
          animated
          @click="emit('next')"
        >
          Continue with {{ mainProduct?.product.title }}

          <template #icon-right>
            <IconArrowRight />
          </template>
        </BaseButton>

        <BaseButton
          class="md:order-first"
          theme="outlined"
          data-testpl="checkout-info-back-btn"
          animated
          @click="emit('back')"
        >
          Back

          <template #icon-left>
            <IconArrowLeft />
          </template>
        </BaseButton>
      </div>

      <div class="mt-60 text-12 text-primary-300">
        <p>Results may vary based on starting weight and program adherence. Medication prescriptions are at the discretion of medical providers and may not be suitable for everyone. Consult a healthcare professional before starting any weight loss program.</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  &::part(container) {
    @apply
      pt-36
      pb-64
      overflow-visible;
  }

  swiper-slide {
    transition: opacity 0.4s;

    &.swiper-slide-prev,
    &.swiper-slide-active,
    &.swiper-slide-next {
      opacity: 1;
    }

    video, img {
      transition: transform 0.3s;
    }

    .label {
      will-change: opacity;
      transition: opacity 0.3s;
      opacity: 0;
    }

    &.swiper-slide-active {
      video, img {
        transform: scale(1.1);
      }

      .label {
        opacity: 1;
      }
    }
  }
}
</style>
