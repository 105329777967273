import { PAYMENT_METHOD_TYPE } from '@models/payment-method';
import { Product } from '@models/product';
import { User } from '@models/user';
import { Variant } from '@models/variant';

export enum PAYMENT_STATE {
    SUCCEEDED = 'succeeded',
    CANCELLED = 'cancelled'
}

export enum CART_SOURCE {
    PHARMACY = 'pharmacy',
    QUIZ = 'quiz',
}

export enum CART_STATUS {
    UNCOMPLETED = 0,
    COMPLETED = 1
}

export interface CartItemPayload {
    productId: number
    variantId: number | null
    quantity: number
    dosage?: number | null
    dosageUnit?: string
    variant?: Variant | null
}

export interface CreatePaymentPayload {
    eventId: string
}

export interface CartUpdatePayload {
    items: CartItemPayload[]
    usage?: string
    dosage?: string
    clear?: boolean
    submissionId?: number
    coupon?: string
    eventId: string
}

export interface BuyNowPayload extends CartUpdatePayload {
  source?: 'pharmacy' | 'quiz'
  coupon: string;
  shippingAddress: CartAddress;
  billingAddress: CartAddress | null;
}

export interface CartAddress {
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2?: string;
  city: string;
  zip: string;
  state_code: string;
}

export interface CartUpdateItemPayload {
    id?: number
    productId: number
    variantId: number
    quantity: number
    eventId: string
}

export interface CartRemoveItemPayload {
    id: number
    eventId: string
}

export interface ApplyCouponPayload {
    coupon: string
}

export interface ToggleTypePayload {
    price: string
}

export interface CartItem {
    id: number
    cartId: number
    quantity: number
    amount: number
    subtotal: number
    total: number
    shipping: number
    discount: number
    dateCreated: string
    dateUpdated: string
    variantId: number
    productId: number
    variant?: Variant
    product?: Product
    dosage?: number
    dosageUnit?: string
}

export interface Cart {
    id: number
    status: CART_STATUS
    source: CART_SOURCE
    subtotal: number
    shipping: number
    discount: number
    total: number
    submissionId: number
    userId: number
    currency: string
    dateCompleted: string
    dateUpdated: string
    dateCreated: string
    user: Pick<User, 'id' | 'fullName'>,

    items: CartItem[],
}

// @deprecated
export interface CartPaymentResponse {
    url: string
    customer: {
        id: string
        name: string
        phone: string|null
        shipping: {
            name: string
            phone: string|null
            address: {
                line1: string;
                line2: string;
                city: string;
                state: string;
                postal_code: string;
                country: string;
            }|null,
        }
    },
    paymentIntent: {
        id: string
        paymentMethod: string
        paymentMethodType: PAYMENT_METHOD_TYPE
        clientSecret: string
    },
}

export interface TrackAddToCartPayload {
  eventId: string,
  currency: string,
  value: number,
  items: {
    id: string | undefined,
    name: string,
    category: string | undefined,
    price: number,
    quantity: number,
  }[]
}

export interface CartBuyNowResponse {
  url: string
}

export interface CartEstimateResponse {
  estimation: {
    next_invoice_estimate?: {
      discounts: Array<{
        amount: number
      }>
    }
  }
}
