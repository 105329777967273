<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { computed, ref, useSlots } from 'vue';
import type { BaseInputType, BaseInputSize } from './types';

const props = withDefaults(defineProps<{
  modelValue?: string;
  debounce?: number;
  placeholder?: string;
  label?: string;
  type?: BaseInputType;
  disabled?: boolean;
  hint?: string;
  error?: string;
  autofocus?: boolean;
  name?: string;
  size?: BaseInputSize;
  dataTestpl?: string;
  inputmode?: 'text' | 'email' | 'tel' | 'url' | 'numeric' | 'search' | 'none' | 'decimal';
  id?: string;
  autocomplete?: string;
}>(), {
  modelValue: '',
  debounce: 0,
  placeholder: '',
  label: '',
  type: 'text',
  disabled: false,
  hint: '',
  error: '',
  autofocus: false,
  name: '',
  size: 'md',
  dataTestpl: undefined,
  inputmode: undefined,
  id: undefined,
  autocomplete: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: string];
  'focus': [];
  'blur': [];
  'enter': [];
}>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    onInput(value);
  },
});

const slots = useSlots();
const isFocused = ref(false);
const input = ref<HTMLInputElement | null>(null);

const onInput = useDebounceFn((value: string) => {
  emit('update:modelValue', value);
}, props.debounce);

const onFocus = () => {
  isFocused.value = true;
  input.value?.focus();
  emit('focus');
};

const onBlur = () => {
  isFocused.value = false;
  input.value?.blur();
  emit('blur');
};

const onEnter = () => {
  emit('enter');
};

const appearanceClass = computed(() => {
  if (props.disabled) {
    return 'opacity-50 outline-transparent';
  }
  if (props.error) {
    return 'outline-error-500';
  }
  if (isFocused.value) {
    return 'outline-primary-500';
  }
  return 'outline-transparent';
});

defineExpose({
  input,
  focus: onFocus,
  blur: onBlur,
  enter: onEnter,
});
</script>

<template>
  <div>
    <p
      v-if="label"
      class="mb-10 text-12 font-medium uppercase"
    >
      {{ label }}
    </p>

    <label
      class="relative
            flex
            h-[49px]
            w-full
            gap-12
            rounded-[5px]
            border
            border-primary-200
            bg-white
            px-16
            text-16
            text-black
            outline
            outline-2
            -outline-offset-1
            transition-all
            duration-200"
      :class="[
        appearanceClass,
      ]"
    >
      <!-- Left slot for icon or dropdown -->
      <span
        v-if="slots.left"
        class="flex shrink-0 items-center"
      >
        <slot name="left" />
      </span>

      <input
        :id="id"
        ref="input"
        v-model="model"
        :data-testpl="dataTestpl"
        class="h-full
              w-full
              bg-transparent
              text-16
              outline-none
              placeholder:text-primary-300"
        :placeholder="placeholder"
        :type="type"
        :name="name"
        :disabled="disabled"
        :autofocus="autofocus"
        :inputmode="inputmode"
        :autocomplete="autocomplete"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter="onEnter"
      >

      <!-- Right slot for icon or dropdown -->
      <span
        v-if="slots.right"
        class="flex shrink-0 items-center"
      >
        <slot name="right" />
      </span>
    </label>

    <p
      v-if="error"
      class="mt-8 text-14 text-error-500"
    >
      {{ error }}
    </p>
    <p
      v-else-if="hint"
      class="mt-8 text-14 text-deep-blue-100"
    >
      {{ hint }}
    </p>
  </div>
</template>
