export enum MODAL_TYPE {
  LOGIN = 'login',
  LOGIN_V2 = 'login-v2',
  LOGIN_PWA = 'loginPwa',
  RESET_PASSWORD = 'resetPassword',
  RESET_PASSWORD_V2 = 'resetPassword-v2',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PRIVACY_POLICY = 'privacy-policy',
  TELEHEALTH_CONSENT = 'telehealth-consent',
  SELF_INJECTION = 'jrnys-self-injection-consent-form',
  MORE_INFO = 'moreInfo',
  PRODUCT = 'product',
  PRODUCT_V2 = 'product-v2'
}
