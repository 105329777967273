<script setup lang="ts">
import {  StepContentBody } from '@models/quiz';
import { computed } from 'vue';

type Props = {
  content: StepContentBody,
  answerCount?: number,
  columns?: string,
  image?: string
}

const props = withDefaults(defineProps<Props>(), {
  answerCount: 0,
  columns: '4',
  image: '',
});

const columns = computed(() => Number(props.columns) || 4);

const headerSizeClass = computed(() => {
  return 'text-26 sm:text-40 sm:font-light leading-120';
});

const columnClass = computed(() => {
  if (columns.value >= 8) {
    return `sm:f-col-${columns.value} sm:px-60`;
  }
  return `sm:f-col-${columns.value}`;
});
</script>

<template>
  <div
    class="quiz-header flex flex-col gap-30 sm:gap-40"
    :class="{ 'sm:text-center': content.centered }"
  >
    <div
      v-if="content.header"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <h1
          :class="headerSizeClass"
          data-testid="quiz-header"
          v-html="content.header"
        />
      </div>
    </div>

    <div
      v-if="content.subheader"
      class="f-row"
      :class="{ 'order-first': content.centered }"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <p
          class="font-bold"
          :class="{
            'text-28 sm:text-33': !content.centered,
            'text-18 sm:text-22': content.centered,
          }"
        >
          {{ content.subheader }}
        </p>
      </div>
    </div>

    <div
      v-if="content.text"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <div
          class="text text-16"
          v-html="content.text"
        />
      </div>
    </div>

    <div
      v-if="image"
      class="f-row"
    >
      <div
        class="f-col-full"
        :class="columnClass"
      >
        <figure class="quiz-header__image flex justify-center p-30">
          <img
            :src="content.picture"
            alt=""
          >
        </figure>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-header__image {
  border-radius: 40px;
  box-shadow: $soft-shadow-inset;
}
</style>
