<script setup lang="ts">
import { watch } from 'vue';
import IconClose from './assets/close.svg';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(defineProps<{
  modelValue?: boolean;
}>(), {
  modelValue: false,
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const close = () => emit('update:modelValue', false);

const onPressEscape = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    close();
  }
};

let isScrollInitiallyLocked = false;

watch(
  () => props.modelValue, (isOpen) => {
    if (isOpen) {
      isScrollInitiallyLocked = document.body.style.overflow === 'hidden';
      document.body.style.overflow = 'hidden';
      window.addEventListener('keydown', onPressEscape);
    } else {
      if (!isScrollInitiallyLocked) {
        document.body.style.overflow = '';
      }
      window.removeEventListener('keydown', onPressEscape);
    }
  },
);
</script>

<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div
        v-if="modelValue"
        class="fixed inset-0 z-[9999] flex flex-col overflow-auto p-20 md:p-24"
      >
        <div
          class="fixed inset-0 bg-black opacity-50"
          @click="close"
        />

        <div
          class="relative m-auto w-full rounded-[5px] bg-white px-16 py-32 md:px-24 md:py-48"
          v-bind="$attrs"
        >
          <button
            class="absolute right-16 top-32 text-black hover:text-primary-500 md:right-24 md:top-48"
            type="button"
            @click="close"
          >
            <IconClose />
          </button>

          <slot />
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}
</style>
