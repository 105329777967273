<script setup lang="ts">
import BaseButton from '@components/quiz_v2/BaseButton/BaseButton.vue';
import { formatUnitAmountCents } from '@helpers/tools';
import { StepCheckout } from '@models/quiz';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { useQuizStore } from '@stores/quiz';
import { pick } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, nextTick } from 'vue';
import IconArrowLeft from '../../assets/icons/arrow-left.svg';
import IconArrowRight from '../../assets/icons/arrow-right.svg';
import IconCheck from '../../assets/icons/check.svg';
import IconLock from '../../assets/icons/lock.svg';
import CheckoutCartAddress from '../CheckoutCartAddress/CheckoutCartAddress.vue';
// import CheckoutCartAgreement from '../CheckoutCartAgreement/CheckoutCartAgreement.vue';
import CheckoutCartCoupon from '../CheckoutCartCoupon/CheckoutCartCoupon.vue';
import CheckoutCartPayment from '../CheckoutCartPayment/CheckoutCartPayment.vue';
import CheckoutCartTotal from '../CheckoutCartTotal/CheckoutCartTotal.vue';
import CheckoutProductCardSmall from '../CheckoutProductCardSmall/CheckoutProductCardSmall.vue';
import PeriodItem from '../PeriodItem/PeriodItem.vue';
import { Period } from '@/js/models/checkout';
import { VARIANT_TYPE } from '@/js/models/variant';

const props = withDefaults(defineProps<{
  content: StepCheckout;
  name?: string;
}>(), {
  name: '',
});

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

const checkoutStore = useCheckoutStore();
const quizStore = useQuizStore();

const {
  variantForm,
  variant$,
  checkout$,
  cartItems,
  buyNowLoading,
  mainProduct,
  membershipProduct,
} = storeToRefs(checkoutStore);

const {
  buyNow,
  applyCoupon,
} = checkoutStore;

// TODO: Change to i18n pluralization
const pluralize = (value: number, unit: string) => {
  return value === 1 ? `${value} ${unit}` : `${value} ${unit}s`;
};

// Create sorted options to display in the template
const periodOptions = computed(() => {
  const monthsPerUnit = { day: 0.033, week: 0.25, month: 1, year: 12 };

  return mainProduct.value?.product.variants
    .filter((variant) => (
      variant.variantType === VARIANT_TYPE.SUBSCRIPTION
          && variant.displayInQuiz
          && variant.variantPeriod
    ))
    .map((variant) => {
      const monthCount = monthsPerUnit[variant.variantPeriodUnit!] * variant.variantPeriod!;
      const pricePerMonth = variant.variantAmount / monthCount;
      const periodText = pluralize(variant.variantPeriod!, variant.variantPeriodUnit!);

      return {
        id: `${variant.variantPeriod}${variant.variantPeriodUnit}`,
        title: `${monthCount} Month Plan`,
        label: variant.variantLabel ?? '',
        agreements: variant.variantPaymentAgreements ?? '',
        formattedPrice: `${formatUnitAmountCents(pricePerMonth)}/mo`,
        list: [
          `${formatUnitAmountCents(variant.variantAmount)} charged every ${periodText}`,
          `Equivalent to paying ${formatUnitAmountCents(pricePerMonth)}/mo`,
          `Includes ${periodText} of medication + injection supplies`,
          `Ships every ${variant.variantPeriod! > 3
            ? pluralize(3, variant.variantPeriodUnit!)
            : pluralize(variant.variantPeriod!, variant.variantPeriodUnit!)}`,
        ],
        monthCount,
        value: variant.variantPeriod,
        unit: variant.variantPeriodUnit,
      };
    })
    .sort((a, b) => b.monthCount - a.monthCount) || [];
});

const setPeriod = (period: Period) => {
  variantForm.value.period = pick(period, ['value', 'unit']);
  // TODO: Needs to be reworked
  nextTick(() => {
    applyCoupon();
  });
};

// Set default period
const threeMonthPeriod = periodOptions.value.find((option) => option.monthCount === 3);

if (threeMonthPeriod) {
  setPeriod(threeMonthPeriod);
}

const activePeriodOption = computed(() => {
  return periodOptions.value.find((option) => {
    return option.value === variantForm.value.period.value
        && option.unit === variantForm.value.period.unit;
  });
});

const isPaymentDisabled = computed(() => variant$.value.$invalid || checkout$.value.$invalid);
const isVariantSelected = computed(() => cartItems.value.length > +!!membershipProduct.value);

const subtitle = computed(() => {
  return props.name
    ? `${props.name}’s treatment details`
    : 'Treatment details';
});

const currentWeight = computed(() => {
  const value = quizStore.answerArray
    .find(({ id }) => id === props.content.currentWeight[0])
    ?.value || 0;

  return Number(value);
});

const loseWeight = computed(() => {
  const finalWeight = quizStore.answerArray
    .find(({ id }) => id === props.content.goal[0])
    ?.value || 0;

  return +(currentWeight.value - Number(finalWeight)).toFixed(2);
});
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="checkout-slide-left w-full max-w-[570px]">
      <div class="-mx-20 md:mx-0">
        <h1 class="px-24 text-28 font-medium text-primary-400">
          You’re almost done
        </h1>

        <div class="mt-32 rounded-[10px] bg-white px-20 py-30">
          <header>
            <h2 class="text-18 font-bold">
              {{ subtitle }}
            </h2>
            <p
              v-if="loseWeight > 0"
              class="mt-4 text-24 text-primary-500"
            >
              Based on your goal to lose {{ loseWeight }} pounds
            </p>
          </header>

          <CheckoutProductCardSmall
            v-if="mainProduct"
            class="mt-30"
            :product="mainProduct"
          />

          <div
            class="mt-36"
            :class="{ 'validation-error': variant$.period.$error }"
          >
            <ul class="flex flex-col gap-16">
              <li
                v-for="period in periodOptions"
                :key="period.id"
              >
                <PeriodItem
                  :title="period.title"
                  :price="period.formattedPrice"
                  :label="period.label"
                  :is-open="period.value === variantForm.period.value && period.unit === variantForm.period.unit"
                  @click="setPeriod(period)"
                >
                  <p class="text-14 font-bold text-primary-500">
                    Plan Breakdown
                  </p>

                  <ul class="mt-16 flex list-inside list-disc flex-col gap-10 pl-10 text-14">
                    <li
                      v-for="(text, index) in period.list"
                      :key="index"
                      :class="{
                        'text-primary-500': index === 1,
                      }"
                    >
                      {{ text }}
                    </li>
                  </ul>

                  <div class="mt-28 rounded-[5px] bg-primary-50 p-20 text-center text-12 text-black">
                    <p class="mx-auto max-w-[248px]">
                      Cancel or change your plan anytime from your online account portal or the app
                    </p>
                  </div>

                  <div class="mt-28 flex items-start justify-between gap-16">
                    <p class="inline-block text-12">
                      FSA/HSA eligible for reimbursement
                    </p>

                    <div class="flex items-center gap-10 text-12">
                      <IconCheck class="h-20 w-20" />
                      <span>Selected</span>
                    </div>
                  </div>
                </PeriodItem>
              </li>
            </ul>

            <p
              v-if="variant$.period.$error"
              class="mt-8 text-14 text-error-500"
            >
              {{ variant$.period.$errors[0]?.$message }}
            </p>
          </div>

          <template v-if="isVariantSelected">
            <CheckoutCartCoupon class="mt-36" />
            <!-- <CheckoutCartAgreement class="mt-36" /> -->

            <div class="mt-36 border-t border-primary-200 pt-32">
              <CheckoutCartTotal />
            </div>
          </template>
        </div>
      </div>

      <div class="mt-40 md:px-20">
        <div v-if="isVariantSelected">
          <CheckoutCartAddress :states="content.states" />
          <CheckoutCartPayment class="mt-40" />
        </div>
      </div>

      <div class="mt-40 grid items-center gap-x-20 gap-y-16 md:grid-cols-[185px,1fr]">
        <BaseButton
          :color="isPaymentDisabled ? 'primary' : 'black'"
          data-testpl="checkout-cart-continue-btn"
          animated
          :loading="buyNowLoading"
          @click="buyNow"
        >
          Pay Now and Submit to Provider

          <template #icon-right>
            <IconArrowRight />
          </template>
        </BaseButton>

        <BaseButton
          class="md:order-first"
          theme="outlined"
          data-testpl="checkout-cart-back-btn"
          animated
          @click="emit('back')"
        >
          Back

          <template #icon-left>
            <IconArrowLeft />
          </template>
        </BaseButton>
      </div>

      <div
        v-if="activePeriodOption?.agreements"
        class="mt-60 flex flex-col gap-10 text-center text-12 text-black opacity-50"
        v-html="activePeriodOption.agreements"
      />

      <div class="mt-20 flex items-center justify-center gap-12 text-14 font-bold uppercase text-black opacity-50 md:mt-24">
        <IconLock />
        <span>256-Bit TLS Security</span>
      </div>
    </div>
  </div>
</template>
