<script setup lang="ts">
import { StepContentBody } from '@models/quiz';
import gsap from 'gsap';
import { ref, computed, onMounted } from 'vue';

type Props = {
  content: StepContentBody
}

defineProps<Props>();
const emit = defineEmits<{ next: [] }>();

const MIN_VALUE = 14;
const STEP_VALUE = 9;

const value = ref(MIN_VALUE);
const rotation = computed(() => (-value.value * 180) / 100 + 90);
const stepIndex = computed(() => Math.ceil((value.value - MIN_VALUE) / STEP_VALUE));

const steps = {
  14: '10-16 lbs.',
  23: '15-26 lbs.',
  32: '20-36 lbs.',
  41: '25-46 lbs.',
  50: '30-56 lbs.',
  59: '35-66 lbs.',
  68: '40-76 lbs.',
  77: '45-86 lbs.',
  86: '50-96 lbs.',
};

onMounted(() => {
  const tl = gsap.timeline({
    onComplete() {
      setTimeout(() => emit('next'), 600);
    },
  });

  Object.keys(steps).forEach((v) => {
    tl.to(value, {
      value: +v,
      duration: 0.3,
      delay: 0.3,
      ease: 'sine.out',
    });
  });
});
</script>

<template>
  <div class="quiz-animation">
    <div class="f-row">
      <div class="f-col-4 flex flex-col items-center">
        <h2
          v-if="content.header"
          class="text-22 font-bold"
        >
          {{ content.header }}
        </h2>

        <figure class="mt-18 text-primary-500 first:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="202"
            height="116"
            fill="none"
            viewBox="0 0 202 116"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M52.85 93.293h14.69c2.27 0 4.402-1.191 5.493-3.174a31.889 31.889 0 0 1 27.968-16.498 31.889 31.889 0 0 1 27.968 16.498c1.091 1.983 3.222 3.174 5.492 3.174h14.258c-5.967-20.753-25.16-35.945-47.934-35.945-22.775 0-41.968 15.197-47.935 35.945Z"
            />
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M67.54 93.242c2.27 0 4.4-1.19 5.49-3.17a31.89 31.89 0 0 1 27.97-16.5c12.06 0 22.55 6.67 27.97 16.5 1.09 1.98 3.22 3.17 5.49 3.17h50.27c11.22-.12 19.04-11.31 15.34-21.84-14.33-40.71-53.28-69.89-99.07-69.89-45.79 0-84.74 29.18-99.07 69.88-3.71 10.53 4.11 21.72 15.34 21.84h50.27v.01ZM100.785 114.457c5.726 0 10.367-4.618 10.367-10.314 0-5.697-4.641-10.315-10.367-10.315-5.726 0-10.368 4.618-10.368 10.315 0 5.696 4.642 10.314 10.368 10.314Z"
            />
            <mask
              id="a"
              width="201"
              height="93"
              x="0"
              y="1"
              maskUnits="userSpaceOnUse"
              style="mask-type:alpha"
            >
              <path
                fill="currentColor"
                d="M67.54 93.242c2.27 0 4.4-1.19 5.49-3.17a31.89 31.89 0 0 1 27.97-16.5c12.06 0 22.55 6.67 27.97 16.5 1.09 1.98 3.22 3.17 5.49 3.17h50.27c11.22-.12 19.04-11.31 15.34-21.84-14.33-40.71-53.28-69.89-99.07-69.89-45.79 0-84.74 29.18-99.07 69.88-3.71 10.53 4.11 21.72 15.34 21.84h50.27v.01Z"
              />
            </mask>
            <g
              fill="currentColor"
              mask="url(#a)"
            >
              <g
                class="quiz-animation__indicator"
                :style="{ transform: `rotate(${rotation}deg)` }"
              >
                <path
                  fill-opacity=".2"
                  d="M101 18.566c47.495 0 86 38.505 86 86s-38.505 86-86 86v-172Z"
                />
                <path d="M100 14.512h2v180h-2z" />
              </g>
            </g>
          </svg>
        </figure>

        <div class="mt-16 text-33 font-bold">
          Month
          <span class="quiz-animation__month">
            <TransitionGroup name="slide-top">
              <template
                v-for="(_, __, index) in steps"
                :key="index"
              >
                <span
                  v-if="index === stepIndex"
                  class="absolute"
                >
                  {{ index + 1 }}
                </span>
              </template>
            </TransitionGroup>
          </span>
        </div>

        <div class="quiz-animation__weight mt-12 text-22 font-bold text-primary-500">
          <TransitionGroup name="slide-left">
            <template
              v-for="(text, _, index) in steps"
              :key="index"
            >
              <p
                v-if="index === stepIndex"
                class="absolute"
              >
                {{ text }}
              </p>
            </template>
          </TransitionGroup>
        </div>

        <p class="mt-30 text-center text-14 sm:mt-48">
          *Results are contingent on following a reduced-calorie diet and increasing physical activity. Individual outcomes may vary from person to person.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-animation__indicator {
  transform-origin: 50% 90%;
}

.quiz-animation__month {
  position: relative;
  display: inline-flex;
  justify-content: center;
  line-height: 1;

  &::before {
    content: '0';
    display: inline;
    opacity: 0;
  }
}

.quiz-animation__weight {
  position: relative;
  display: flex;
  justify-content: center;
  align-self: stretch;

  &::before {
    content: 'x';
    display: inline;
    opacity: 0;
  }
}
</style>
