<script setup lang="ts">
import BaseAccordion from '@components/quiz_v2/BaseAccordion/BaseAccordion.vue';
import BaseButton from '@components/quiz_v2/BaseButton/BaseButton.vue';
import { type CartProduct } from '@models/product';
import { type StepCheckout } from '@models/quiz';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { ref, onMounted, onUnmounted, computed } from 'vue';
import IconArrowRight from '../../assets/icons/arrow-right.svg';
import IconCheck from '../../assets/icons/check.svg';
import imgPhone from '../../assets/img/checkout-promo-phone.webp';
import CheckoutProductCard from '../CheckoutProductCard/CheckoutProductCard.vue';
import ModalProductOptions from '../ModalProductOptions/ModalProductOptions.vue';
import { tools, help } from './mock';

const props = withDefaults(defineProps<{
  content: StepCheckout;
  productList: CartProduct[];
  name?: string;
}>(), {
  name: '',
});

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

const checkoutStore = useCheckoutStore();
const { mainProduct } = storeToRefs(checkoutStore);

const isModalProductOptionsShown = ref(false);

const stickyButtonRef = ref<HTMLElement | null>(null);
const stickyButtonOffset = ref<string>('auto');

const onWindowResize = debounce(async () => {
  if (!stickyButtonRef.value) return;

  const offset = stickyButtonRef.value?.getBoundingClientRect().top;
  const maxOffset = document.documentElement.clientHeight - stickyButtonRef.value.clientHeight - 20;
  stickyButtonOffset.value = offset > maxOffset ? `${maxOffset}px` : `${offset}px`;
}, 100);

onMounted(() => {
  window.addEventListener('resize', onWindowResize);
  window.dispatchEvent(new Event('resize'));
});

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize);
});

const title = computed(() => {
  return props.name
    ? `${props.name}, based on your answers, you might qualify for:`
    : 'Based on your answers, you might qualify for:';
});
</script>

<template>
  <div class="grid">
    <ModalProductOptions
      v-model="isModalProductOptionsShown"
      class="max-w-[573px]"
      :list="productList"
      @select="(product) => checkoutStore.mainProduct = product"
    />

    <div class="flex flex-col items-center overflow-hidden">
      <div class="w-full max-w-[530px]">
        <h1 class="text-24 font-medium text-primary-400 md:text-28">
          {{ title }}
        </h1>

        <CheckoutProductCard
          v-if="mainProduct"
          class="mt-32"
          :product="mainProduct"
        />
      </div>
    </div>

    <div class="mt-24 flex flex-col items-center overflow-hidden md:mt-40">
      <div class="checkout-slide-left w-full max-w-[530px]">
        <BaseButton
          class="w-full"
          data-testpl="checkout-promo-other-options-btn"
          animated
          @click="isModalProductOptionsShown = true"
        >
          Other Treatment Options

          <template #icon-right>
            <IconArrowRight />
          </template>
        </BaseButton>
      </div>
    </div>

    <div
      ref="stickyButtonRef"
      class="sticky z-10 mt-16 flex flex-col items-center overflow-hidden md:mt-10"
      :style="{ top: stickyButtonOffset }"
    >
      <div class="checkout-slide-left w-full max-w-[530px]">
        <BaseButton
          class="w-full"
          color="black"
          data-testpl="checkout-promo-continue-btn"
          animated
          @click="emit('next')"
        >
          Continue with {{ mainProduct?.product.title }}

          <template #icon-right>
            <IconArrowRight />
          </template>
        </BaseButton>
      </div>
    </div>

    <div
      v-if="mainProduct?.infoCards.length"
      class="-mx-20 mt-40 flex flex-col items-center overflow-hidden px-20 md:mt-60 lg:-mx-40 lg:px-40"
    >
      <div class="checkout-slide-left w-full max-w-[530px]">
        <swiper-container
          class="slider"
          slides-per-view="auto"
          :mousewheel="{
            enabled: true,
            forceToAxis: true,
          }"
          data-testpl="checkout-promo-slider"
        >
          <swiper-slide
            v-for="(slide, index) in mainProduct.infoCards"
            :key="index"
            class="h-auto w-[353px]"
            data-testpl="checkout-promo-slide"
          >
            <div class="h-full rounded-[20px] border border-primary-200 bg-white p-[30px]">
              <h4 class="text-20 font-bold">
                {{ slide.title }}
              </h4>
              <div
                class="mt-36 whitespace-pre-wrap text-20"
                v-html="slide.content"
              />
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="mt-40 flex flex-col items-center overflow-hidden pb-[50vh]">
      <div class="checkout-slide-left w-full max-w-[530px]">
        <section class="md:pl-20">
          <h2 class="text-24 font-bold text-primary-500">
            Answers to all your questions
          </h2>

          <div class="mt-4">
            <BaseAccordion
              v-for="(item, index) in content.faqItems"
              :key="index"
              :title="item.title"
            >
              <div
                class="prose text-14"
                v-html="item.content"
              />
            </BaseAccordion>
          </div>
        </section>

        <img
          class="mt-40 w-full rounded-[20px]"
          :src="imgPhone"
          alt=""
        >

        <section class="mt-40 md:px-30">
          <h2 class="text-24 font-medium">
            Concierge medical team and tools to help you meet your goals
          </h2>

          <ul class="mt-28 flex flex-col gap-20">
            <li
              v-for="(item, index) in tools"
              :key="index"
              class="flex items-start gap-20"
            >
              <IconCheck class="shrink-0 text-primary-500" />

              <div class="text-14">
                <h3 class="font-bold">
                  {{ item.title }}
                </h3>
                <p class="mt-8">
                  {{ item.text }}
                </p>
              </div>
            </li>
          </ul>
        </section>

        <section class="mt-30 md:px-30">
          <h2 class="text-24 font-medium">
            We’re here to help, at every step
          </h2>

          <ul class="mt-28 flex flex-col gap-20">
            <li
              v-for="(item, index) in help"
              :key="index"
              class="flex items-start gap-20"
            >
              <IconCheck class="shrink-0 text-primary-500" />

              <div class="text-14">
                <h3 class="font-bold">
                  {{ item.title }}
                </h3>
                <p class="mt-8">
                  {{ item.text }}
                </p>
              </div>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  &::part(container) {
    @apply
      overflow-visible;
  }

  swiper-slide:not(:last-child) {
    @apply
      mr-20;
  }
}
</style>
