<script setup lang="ts">
import BaseButton from '@components/quiz_v2/BaseButton/BaseButton.vue';
import BaseInput from '@components/quiz_v2/BaseInput/BaseInput.vue';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import IconMinus from '../../assets/icons/minus.svg';
import IconPlus from '../../assets/icons/plus.svg';

const checkoutStore = useCheckoutStore();

const {
  checkoutForm,
  couponInput,
  estimateLoading,
} = storeToRefs(checkoutStore);

const {
  applyCoupon,
  removeCoupon,
} = checkoutStore;

onMounted(() => {
  applyCoupon();
});
</script>

<template>
  <form
    class="hidden"
    @submit.prevent="applyCoupon"
  >
    <h2 class="text-24 font-medium">
      Have a coupon code?
    </h2>

    <div class="mt-24 flex items-end gap-12">
      <template v-if="!checkoutForm.coupon">
        <BaseInput
          v-model="couponInput"
          class="grow"
          data-testpl="checkout-cart-coupon-input"
        />
        <BaseButton
          size="sm"
          rounded
          data-testpl="checkout-cart-coupon-submit"
          type="submit"
          :loading="estimateLoading"
        >
          <template #icon-left>
            <IconPlus class="h-20 w-20" />
          </template>
        </BaseButton>
      </template>

      <template v-else>
        <BaseInput
          :model-value="checkoutForm.coupon"
          class="grow"
          data-testpl="checkout-cart-coupon-result"
          disabled
        />

        <BaseButton
          size="sm"
          rounded
          data-testpl="checkout-cart-coupon-remove"
          @click="removeCoupon"
        >
          <template #icon-left>
            <IconMinus />
          </template>
        </BaseButton>
      </template>
    </div>
  </form>
</template>
