import { Loader } from '@googlemaps/js-api-loader';
import { camelCase } from 'lodash';
import { ref } from 'vue';

type Places = Record<string, { longName: string, shortName: string }>;

const placesAutocompleteService = ref();

new Loader({
  apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places'],
  language: 'en',
})
  .importLibrary('places')
  .then((response) => placesAutocompleteService.value = response);

export const useAutocomplete = () => {
  const autocomplete = (input: HTMLInputElement, cb: (options: Places) => void) => {
    const autocompleteInstance = new placesAutocompleteService.value.Autocomplete(input, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });

    autocompleteInstance?.addListener('place_changed', () => {
      const places = autocompleteInstance.getPlace().address_components.reduce((
        acc: Record<string, string>,
        curr: { long_name: string, short_name: string, types: string[] },
      ) => {
        return { ...acc, [camelCase(curr.types[0])]: { longName: curr.long_name, shortName: curr.short_name } };
      }, {}) as Places;

      cb(places);
    });
  };

  return {
    autocomplete,
  };
};
