<script setup lang="ts">
import BaseInput from '@components/quiz_v2/BaseInput/BaseInput.vue';
import BaseSelect from '@components/quiz_v2/BaseSelect/BaseSelect.vue';
import BaseSwitch from '@components/quiz_v2/BaseSwitch/BaseSwitch.vue';
import { optionsPhone, optionsPostcode } from '@composables/useMaskOptions';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { vMaska } from 'maska';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, ref, watch } from 'vue';
import { useAutocomplete } from '../../composables/useAutocomplete';

defineProps<{
  states: { label: string, value: string }[];
}>();

const emit = defineEmits<{
  'next': [];
  'back': [];
}>();

const checkoutStore = useCheckoutStore();
const { autocomplete } = useAutocomplete();

const {
  checkoutForm,
  checkout$,
  isBillingAddressSame,
} = storeToRefs(checkoutStore);

const shippingAddressInput = ref<InstanceType<typeof BaseInput> | null>(null);
const billingAddressInput = ref<InstanceType<typeof BaseInput> | null>(null);

onMounted(() => {
  autocomplete(shippingAddressInput.value?.input!, (places) => {
    checkoutForm.value.shippingAddress.line1 = [
      places.streetNumber?.longName,
      places.route?.longName,
    ].filter(Boolean).join(' ');

    checkoutForm.value.shippingAddress.city = places.locality?.longName ?? '';
    checkoutForm.value.shippingAddress.state_code = places.administrativeAreaLevel1?.shortName ?? '';

    checkoutForm.value.shippingAddress.zip = [
      places.postalCode?.longName,
      places.postalCodeSuffix?.longName,
    ].filter(Boolean).join('-');
  });
});

watch(isBillingAddressSame, async (isBillingAddressSame) => {
  if (isBillingAddressSame) {
    return;
  }

  await nextTick();

  autocomplete(billingAddressInput.value?.input!, (places) => {
    checkoutForm.value.billingAddress!.line1 = [
      places.streetNumber?.longName,
      places.route?.longName,
    ].filter(Boolean).join(' ');

    checkoutForm.value.billingAddress!.city = places.locality?.longName ?? '';
    checkoutForm.value.billingAddress!.state_code = places.administrativeAreaLevel1?.shortName ?? '';

    checkoutForm.value.billingAddress!.zip = [
      places.postalCode?.longName,
      places.postalCodeSuffix?.longName,
    ].filter(Boolean).join('-');
  });
});
</script>

<template>
  <div :class="{ 'validation-error': checkout$.shippingAddress.$error }">
    <h1 class="text-24 font-medium">
      If prescribed, where should we send your treatment?
    </h1>

    <form class="mt-24">
      <div class="flex flex-col gap-20">
        <BaseInput
          v-model="checkoutForm.shippingAddress.firstName"
          label="First Name"
          data-testpl="checkout-address-first-name-input"
          :error="checkout$.shippingAddress.firstName.$errors[0]?.$message"
        />

        <BaseInput
          v-model="checkoutForm.shippingAddress.lastName"
          label="Last Name"
          data-testpl="checkout-address-last-name-input"
          :error="checkout$.shippingAddress.lastName.$errors[0]?.$message"
        />

        <BaseInput
          ref="shippingAddressInput"
          v-model="checkoutForm.shippingAddress.line1"
          label="Address"
          data-testpl="checkout-address-line1-input"
          :error="checkout$.shippingAddress.line1.$errors[0]?.$message"
        />

        <BaseInput
          v-model="checkoutForm.shippingAddress.line2"
          label="Apartment, unit, suite, or floor #"
          data-testpl="checkout-address-line2-input"
        />

        <BaseInput
          v-model="checkoutForm.shippingAddress.city"
          label="City"
          data-testpl="checkout-address-city-input"
          :error="checkout$.shippingAddress.city.$errors[0]?.$message"
        />

        <BaseSelect
          v-model="checkoutForm.shippingAddress.state_code"
          :options="states"
          label="State"
          data-testpl="checkout-address-state-input"
          :error="checkout$.shippingAddress.state_code.$errors[0]?.$message"
        />

        <!-- TODO: Fix type -->
        <BaseInput
          v-model="checkoutForm.shippingAddress.zip"
          v-maska:[optionsPostcode]
          label="Zip Code"
          data-testpl="checkout-address-zip-code-input"
          :error="checkout$.shippingAddress.zip.$errors[0]?.$message"
        />

        <!-- TODO: Fix type -->
        <BaseInput
          v-model="checkoutForm.shippingAddress.phone"
          v-maska:[optionsPhone]
          label="Phone"
          data-testpl="checkout-address-phone-input"
          :error="checkout$.shippingAddress.phone.$errors[0]?.$message"
        />
      </div>

      <div
        class="mt-32 md:mt-36"
        :class="{ 'validation-error': checkout$.billingAddress.$error }"
      >
        <BaseSwitch
          v-model="isBillingAddressSame"
          class="mt-24"
          data-testpl="checkout-address-billing-switch"
        >
          Use this as my billing address
        </BaseSwitch>
      </div>

      <template v-if="checkoutForm.billingAddress">
        <h2 class="mt-40 text-24 font-medium">
          Add your billing address:
        </h2>

        <div class="mt-24 flex flex-col gap-20">
          <BaseInput
            v-model.trim="checkoutForm.billingAddress.firstName"
            label="First Name"
            data-testpl="checkout-address-first-name-input"
            :error="checkout$.billingAddress.firstName.$errors[0]?.$message"
          />

          <BaseInput
            v-model.trim="checkoutForm.billingAddress.lastName"
            label="Last Name"
            data-testpl="checkout-address-last-name-input"
            :error="checkout$.billingAddress.lastName.$errors[0]?.$message"
          />

          <BaseInput
            ref="billingAddressInput"
            v-model.trim="checkoutForm.billingAddress.line1"
            label="Address"
            data-testpl="checkout-address-line1-input"
            :error="checkout$.billingAddress.line1.$errors[0]?.$message"
          />

          <BaseInput
            v-model.trim="checkoutForm.billingAddress.line2"
            label="Apartment, unit, suite, or floor #"
            data-testpl="checkout-address-line2-input"
          />

          <BaseInput
            v-model.trim="checkoutForm.billingAddress.city"
            label="City"
            data-testpl="checkout-address-city-input"
            :error="checkout$.billingAddress.city.$errors[0]?.$message"
          />

          <BaseSelect
            v-model="checkoutForm.billingAddress.state_code"
            :options="states"
            label="State"
            data-testpl="checkout-address-state-input"
            :error="checkout$.billingAddress.state_code.$errors[0]?.$message"
          />

          <!-- TODO: Fix type -->
          <BaseInput
            v-model.trim="checkoutForm.billingAddress.zip"
            v-maska:[optionsPostcode]
            label="Zip Code"
            data-testpl="checkout-address-zip-code-input"
            :error="checkout$.billingAddress.zip.$errors[0]?.$message"
          />

          <!-- TODO: Fix type -->
          <BaseInput
            v-model.trim="checkoutForm.billingAddress.phone"
            v-maska:[optionsPhone]
            label="Phone"
            data-testpl="checkout-address-phone-input"
            :error="checkout$.billingAddress.phone.$errors[0]?.$message"
          />
        </div>
      </template>
    </form>
  </div>
</template>
