<script setup lang="ts">
import BaseCheck from '@components/quiz_v2/BaseCheck/BaseCheck.vue';
import { MODAL_TYPE } from '@models/modals';
import { Checkbox } from '@models/quiz';
import { ref, computed, onMounted, getCurrentInstance } from 'vue';

const props = defineProps<{
  content: Checkbox,
  modelValue: boolean
}>();

const emit = defineEmits<{ 'update:modelValue': [value: boolean] }>();
const labelRef = ref<HTMLElement | null>(null);
const internalInstance  = getCurrentInstance();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const textPageTypes = [
  MODAL_TYPE.TERMS_AND_CONDITIONS,
  MODAL_TYPE.PRIVACY_POLICY,
  MODAL_TYPE.TELEHEALTH_CONSENT,
];

onMounted(() => {
  textPageTypes.forEach((type) => {
    const link = labelRef.value?.querySelector(`a[href*="${type}"]`);

    link?.addEventListener('click', (e) => {
      e.preventDefault();
      const root = internalInstance?.appContext.config.globalProperties.root;
      root?.setModal(type, true);
    });
  });
});
</script>

<template>
  <BaseCheck
    v-model="model"
    class="sm:f-col-4 f-col-full gap-10 text-14 sm:max-w-[324px]"
    type="checkbox"
  >
    <span
      ref="labelRef"
      v-html="content.label"
    />
  </BaseCheck>
</template>

<style lang="scss" scoped></style>
