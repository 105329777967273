<script setup lang="ts">
import PromoCode from '@components/PromoCode/PromoCode.vue';
import { PharmacyCartItem } from '@components/pharmacy';
import QuizNavigation from '@components/quiz_v2/QuizNavigation/QuizNavigation.vue';
import TotalGrid from '@components/quiz_v2/TotalGrid/TotalGrid.vue';
import { useMemorize } from '@helpers/use-memorize';
import { StepContentBody } from '@models/quiz';
import { useAnalyticsStore } from '@stores/analytics';
import { useCartStore } from '@stores/cart';
import { watchOnce } from '@vueuse/core';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { useQuizStore } from '@/js/stores';

defineProps<{
  content: StepContentBody;
}>();

const isTrackingLoading = ref(false);
const { beginCheckout } = useAnalyticsStore();
const memorize = useMemorize('quiz-checkout-');

const emit = defineEmits<{
  next: [];
  back: [];
}>();

const cartStore = useCartStore();

const totals = computed(() => cartStore.totalsSummary);
const cartId = computed(() => cartStore.cart?.id);

const {
  topBannerText,
  topBannerPromocode,
} = useQuizStore();

watchOnce(cartId, () => {
  if (!cartId.value) {
    return;
  }

  if (memorize.check(cartId.value)) {
    return;
  }

  memorize.commit(cartId.value);
});

const onCheckoutClick = async () => {
  isTrackingLoading.value = true;

  beginCheckout(
    cartStore.toAnalyticProducts(cartStore.cartItems),
    (cartStore.cart?.total ?? 0) / 100,
    cartStore.cart?.id,
  );

  cartStore.createPayment();
  emit('next');
};

const isPromoCode = ref(false);

onMounted(() => {
  const searchParams = new URLSearchParams(location.search);

  if (searchParams.get('newyear') !== null) {
    isPromoCode.value = true;
  }
});

onBeforeUnmount(() => {
  isPromoCode.value = false;
});
</script>

<template>
  <Teleport to="body">
    <Transition name="promo-code-animation">
      <PromoCode
        v-if="isPromoCode && topBannerText && topBannerPromocode"
        :text="topBannerText"
        :code="topBannerPromocode"
      />
    </Transition>
  </Teleport>

  <div class="quiz-cart">
    <div class="container">
      <div class="f-row">
        <div class="f-col-8 xl:f-col-4">
          <h1
            class="mb-30 text-24 sm:text-50 sm:font-extralight sm:leading-none xl:hidden"
            v-html="content.header"
          />

          <div class="mb-20 rounded-[40px] bg-white p-30 lg:p-40">
            <PharmacyCartItem
              v-for="item in cartStore.cartItems"
              :key="item.id"
              :item="item"
              :counter="false"
              class="mb-10 border-b border-b-warm-grey-30 pb-10 last:border-0 lg:mb-20 lg:pb-20"
            />
            <div
              v-if="!cartStore.cartItems?.length"
              class="text-14 text-warm-grey"
            >
              The cart is empty
            </div>

            <TotalGrid :items="totals" />
          </div>
        </div>

        <div class="f-col-8 xl:f-col-4 xl:order-first">
          <h1
            class="hidden text-37 font-extralight leading-none sm:text-50 xl:block"
            v-html="content.header"
          />

          <div class="mt-30 sm:mt-48">
            <h3 class="text-18 font-bold">
              {{ content.subheader }}
            </h3>

            <ul class="mt-20 flex flex-col">
              <li
                v-for="(feature, index) in content.features"
                :key="index"
                class="quiz-cart__feature flex flex-wrap items-center gap-x-20 gap-y-16 py-22 sm:flex-nowrap sm:items-start"
              >
                <img
                  class="w-[60px] shrink-0"
                  :src="feature.image"
                  :alt="feature.header"
                >
                <span class="shrink-0 text-18 font-bold text-primary-500 sm:w-[101px]">{{ feature.header }}</span>
                <p class="text-14">
                  {{ feature.description }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="f-row">
        <div class="f-col-8">
          <div class="f-col-4">
            <div>
              <p class="mt-30 flex gap-x-10 text-28 font-light sm:mt-40">
                <span class="text-primary-500">Total:</span>
                {{ cartStore.formattedCartTotal }}
              </p>

              <QuizNavigation
                class="mt-20 flex-row-reverse !justify-end gap-20"
                next-class="!bg-deep-blue hover:!bg-primary-500"
                next-text="Continue to checkout"
                :next-loading="isTrackingLoading || cartStore.createPaymentLoading"
                :next-disabled="cartStore.cartUpdateLoading"
                @next="onCheckoutClick"
                @back="emit('back')"
              />
            </div>

            <p class="mt-30 text-14 text-deep-blue-50 sm:mt-48">
              You will only be charged for prescription items if prescribed by a medical provider. Cost shown does not include cost of
              medication.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.quiz-cart__feature {
  &:not(:first-child) {
    border-top: 2px solid rgba(#653434, 0.3);
  }
}

.quiz-cart__plan {
  padding: 20px;
  border-radius: 30px;
  background-color: var(--color-white);
  box-shadow: $soft-shadow;

  @include up("sm") {
    padding: 30px 40px 40px;
    border-radius: 40px;
  }
}

.quiz-cart__addons {
  border-top: 2px solid rgba(#653434, 0.3);
}

.promo-code-animation-enter-active,
.promo-code-animation-leave-active {
  transition: transform 0.3s;
}

.promo-code-animation-enter-from,
.promo-code-animation-leave-to {
  transform: translateY(-100%);
}
</style>
