<script setup lang="ts">
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCvv,
} from '@chargebee/chargebee-js-vue-wrapper';
import BaseButton from '@components/quiz_v2/BaseButton/BaseButton.vue';
import { useCheckoutStore } from '@stores/checkout/checkoutStore';
import { format, parseISO } from 'date-fns';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import IconPlus from '../../assets/icons/plus.svg';
import imgCards from '../../assets/img/checkout-cart-cards.png';
import {
  ChargebeeChangeEvent,
  ChargebeeFieldType,
  ChargebeeFieldError,
} from './types';

const checkoutStore = useCheckoutStore();

const {
  checkoutForm,
  checkout$,
  paymentMethods,
  isNewPaymentMethod,
  cardComponent,
} = storeToRefs(checkoutStore);

const { deletePaymentMethod } = checkoutStore;

const cardSettings = {
  icon: false,
  styles: {
    base: {
      fontFamily: 'BR Firma, sans-serif',
      fontSize: '18px',
      fontStyle: 'normal',
      color: '#111111',

      '::placeholder': {
        color: '#AAA092',
      },
    },
  },
  classes: {
    focus: 'focus',
    invalid: 'invalid',
  },
  fonts: [
    {
      src: 'https://jrnys.com/assets/fonts/BRFirma-Regular.woff2',
      fontFamily: 'BR Firma',
      fontWeight: '400',
      fontStyle: 'normal',
    },
  ],
};

const cardErrors = ref<Partial<Record<ChargebeeFieldType, ChargebeeFieldError>>>({});
const cardErrorMessage = ref<string>();

const onCardChange = (status: ChargebeeChangeEvent) => {
  cardErrors.value = { ...cardErrors.value, [status.field]: status.error };
  const { message } = Object.values(cardErrors.value).filter((message) => !!message).pop() || {};
  cardErrorMessage.value = message;
};

const paymentMethodList = ref(paymentMethods.value);

watch(paymentMethods, (newValue) => {
  paymentMethodList.value = newValue;
});

const addNewPaymentMethod = () => {
  isNewPaymentMethod.value = true;
  checkoutForm.value.paymentSourceId = null;
};

const selectPaymentMethod = (paymentSourceId: string) => {
  checkoutForm.value.paymentSourceId = paymentSourceId;
  isNewPaymentMethod.value = false;
};

const deleteMethod = (paymentSourceId: string) => {
  if (!confirm('Are you sure you want to delete payment method')) {
    return;
  }
  paymentMethodList.value = paymentMethodList.value.filter((method) => {
    return method.externalRef !== paymentSourceId;
  });
  deletePaymentMethod(paymentSourceId);
};
</script>

<template>
  <div>
    <div
      v-if="paymentMethods.length !== 0"
      :class="{ 'validation-error': checkout$.paymentSourceId.$error }"
    >
      <h2 class="text-24 font-medium">
        Choose a saved payment option:
      </h2>
      <p class="mt-12 text-14">
        If prescribed, you’ll be charged $897 every 3 months. You can cancel anytime prior to your next billing cycle.
      </p>

      <ul class="mt-32 flex flex-col gap-12">
        <li
          v-for="method in paymentMethodList"
          :key="method.externalRef"
          @click="selectPaymentMethod(method.externalRef)"
        >
          <button
            class="flex h-[60px] w-full cursor-pointer items-center gap-8 rounded-[5px] border border-primary-500 px-20 text-left md:gap-20"
            :class="{
              'bg-primary-500 text-white': checkoutForm.paymentSourceId === method.externalRef,
            }"
            type="button"
          >
            <span class="text-16 font-bold">Visa ending {{ method.card.ending }}</span>
            <span class="ml-auto text-12">Expires {{ format(parseISO(method.card.expirationDate), 'MM/yy') }}</span>

            <span
              class="p-6 text-12 font-bold hover:text-error-500"
              :class="{
                'text-primary-500': checkoutForm.paymentSourceId !== method.externalRef,
              }"
              @click.stop="deleteMethod(method.externalRef)"
            >
              Remove
            </span>
          </button>
        </li>
      </ul>

      <BaseButton
        v-if="!isNewPaymentMethod"
        class="mt-28 w-full"
        data-testpl="checkout-add-payment-btn"
        @click="addNewPaymentMethod"
      >
        Add new payment method

        <template #icon-right>
          <IconPlus />
        </template>
      </BaseButton>

      <p
        v-if="checkout$.paymentSourceId.$error"
        class="mt-8 text-14 text-error-500"
      >
        {{ checkout$.paymentSourceId.$errors[0]?.$message }}
      </p>
    </div>

    <div
      v-if="isNewPaymentMethod"
      class="mt-40"
      :class="{ 'validation-error': cardErrorMessage }"
    >
      <h2 class="text-24 font-medium">
        Add a payment option
      </h2>
      <!-- <p class="mt-12 text-14">
      If prescribed, you’ll be charged $897 every 3 months. You can cancel anytime prior to your next billing cycle.
    </p> -->

      <div class="mt-32 flex items-center justify-between gap-16">
        <p>Credit or Debit Card</p>
        <img
          class="w-[143px]"
          :src="imgCards"
          alt=""
        >
      </div>

      <CardComponent
        ref="cardComponent"
        class="mt-28 flex flex-wrap gap-16"
        v-bind="cardSettings"
        data-testpl="checkout-cart-card-component"
        @change="onCardChange"
      >
        <div class="w-full">
          <CardNumber
            class="checkout-input"
            placeholder="Credit Card Number"
          />
        </div>
        <div class="grow">
          <CardExpiry
            class="checkout-input"
            placeholder="Expiry"
          />
        </div>
        <div class="w-[170px]">
          <CardCvv
            class="checkout-input"
            placeholder="CVV"
          />
        </div>
      </CardComponent>

      <p
        v-if="cardErrorMessage"
        class="mt-8 text-14 text-error-500"
      >
        {{ cardErrorMessage }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.checkout-input {
  @apply
    relative
    flex
    h-[49px]
    w-full
    items-center
    rounded-[5px]
    px-16
    text-16
    text-black
    outline
    outline-2
    outline-transparent
    -outline-offset-1
    border
    border-primary-200
    bg-white
    transition-all
    duration-200;

  &.focus {
    @apply
      outline-primary-500;
  }

  &.invalid {
    @apply
      outline-error-500;
  }
}
</style>
