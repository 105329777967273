<script setup lang="ts">
import BaseButton from '@components/quiz_v2/BaseButton/BaseButton.vue';
import IconArrowLeft from '@components/quiz_v2/QuizCheckout/assets/icons/arrow-left.svg';
import IconArrowRight from '@components/quiz_v2/QuizCheckout/assets/icons/arrow-right.svg';

type Props = {
  nextText?: string
  nextClass?: string
  backClass?: string
  nextLoading?: boolean
  nextDisabled?: boolean
  nextHidden?: boolean
  backHidden?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  nextText: 'Continue',
  nextClass: '',
  backClass: '',
  nextLoading: false,
  nextDisabled: false,
  nextHidden: false,
  backHidden: false,
});

const emit = defineEmits<{ next: [], back: [] }>();
</script>

<template>
  <div class="flex flex-col flex-wrap items-start justify-center gap-20 sm:flex-row sm:items-center">
    <BaseButton
      v-if="!nextHidden"
      color="black"
      class="min-w-[205px]"
      :class="nextClass"
      :text="props.nextText"
      :loading="nextLoading"
      :disabled="nextDisabled"
      data-testid="quiz-next-button"
      animated
      @click="emit('next')"
    >
      {{ props.nextText }}
      <template #icon-right>
        <IconArrowRight />
      </template>
    </BaseButton>

    <BaseButton
      v-if="!backHidden"
      theme="outlined"
      :class="backClass"
      class="min-w-[140px] sm:order-first"
      data-testid="quiz-back-button"
      text="Back"
      animated
      @click="emit('back')"
    >
      Back
      <template #icon-left>
        <IconArrowLeft />
      </template>
    </BaseButton>
  </div>
</template>

<style lang="scss" scoped></style>
