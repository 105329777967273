<script setup lang="ts">
import {
  STEP_ANSWER_CONTENT_TYPE,
} from '@models/quiz';
import { computed } from 'vue';

type Props = {
  modelValue: any
  value?: any
  type?: 'checkbox' | 'radio'
  size?: number | string
  name?: string
  styled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  type: STEP_ANSWER_CONTENT_TYPE.CHECKBOX,
  size: 20,
  name: '',
  styled: false,
});

const emit = defineEmits<{ 'update:modelValue': [value: any] }>();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <label
    class="base-check"
    :class="{ 'base-check--styled': styled }"
    data-testpl="checkbox-wrapper"
  >
    <input
      v-model="model"
      :value="value"
      class="base-check__input"
      :type="type"
      :name="name"
      data-testpl="checkbox-input"
    >
    <span
      class="base-check__field"
      :style="{ width: `${size}px`, height: `${size}px` }"
      data-testpl="checkbox-input_fake"
    />
    <span
      class="text flex grow items-center justify-between gap-16 self-center"
      data-testpl="checkbox-description_text"
    >
      <slot />
    </span>
  </label>
</template>

<style lang="scss" scoped>
.base-check {
  position: relative;
  display: flex;
  cursor: pointer;

  &--styled {
    gap: 20px;
    padding: 14px 30px 13px 16px;
    border-radius: 5px;
    background-color: var(--color-white);
  }
}

.base-check__input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
}

.base-check__field {
  display: flex;
  flex-shrink: 0;
  border-radius: 100%;
  border: 1px solid theme('colors.primary-500');
  background-color: var(--color-white);
  overflow: hidden;
  transition: border-color 0.3s;

  &::before {
    content: '';
    width: 100%;
    border-radius: 100%;
    background-color: theme('colors.black');
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transform: scale(0);
    opacity: 0;
  }

  .base-check__input:checked + &::before {
    transform: scale(0.7);
    opacity: 1;
  }

  .base-check__input:checked + & {
    border-color: theme('colors.black');
  }

  .base-check:hover &,
  .base-check__input:focus + & {
    border-color: theme('colors.black');
  }
}
</style>
