<script setup lang="ts">
import BaseCheck from '@components/quiz_v2/BaseCheck/BaseCheck.vue';
import { getShippingPriceByAnswer, getUsagePriceByAnswer } from '@helpers/cart-submission';
import {
  StepAnswer,
  CheckElement,
  STEP_ANSWER_CONTENT_TYPE,
  CHECK_POSITION,
} from '@models/quiz';
import { useQuizStore } from '@stores/quiz';
import { uniqBy } from 'lodash';
import { ref, computed, watch } from 'vue';

type Answer = StepAnswer<CheckElement>

type Props = {
  answers: Answer[]
  modelValue: Answer[]
  name: string
  columns?: string
  leftTitle?: string
  rightTitle?: string
  grouped?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  columns: '4',
  leftTitle: '',
  rightTitle: '',
});

const emit = defineEmits<{
  next: [value: any]
  'update:modelValue': [values: Answer[]]
}>();

const quizStore = useQuizStore();

const type = computed(() => props.answers[0].content[0]?.type);
const columns = computed(() => Number(props.columns));
const isRow = computed(() => columns.value >= 6);
const clicked = ref(false);

const columnClass = computed(() => {
  return isRow.value ? `sm:f-col-${columns.value / 2}` : `sm:f-col-${columns.value}`;
});

const values = ref(
  type.value === STEP_ANSWER_CONTENT_TYPE.CHECKBOX
    ? props.modelValue
    : props.modelValue[0],
);
const leftValues = ref(
  props.modelValue.find((answer) => {
    return answer.content[0].content.position === CHECK_POSITION.LEFT;
  }),
);
const rightValues = ref(
  props.modelValue.find((answer) => {
    return answer.content[0].content.position === CHECK_POSITION.RIGHT;
  }),
);

watch(values, (newValues) => {
  const emittedValue = Array.isArray(newValues) ? newValues : [newValues];
  // If checkbox - then skip
  if(!Array.isArray(newValues)) {
    if (!clicked.value) {
      emit('next', newValues.slug);
    }
    clicked.value = true;
    setTimeout(() => clicked.value = false, 500);
  }
  emit('update:modelValue', emittedValue);
}, { deep: true });

watch([leftValues, rightValues], (newValues) => {
  const emittedValue = uniqBy(newValues.flat().filter(Boolean), 'id');
  emit('update:modelValue', emittedValue as Answer[]);
});

const leftAnswers = computed(() => {
  return props.answers.filter((answer) => {
    return answer.content[0].content.position === CHECK_POSITION.LEFT;
  });
});

const rightAnswers = computed(() => {
  return props.answers.filter((answer) => {
    return answer.content[0].content.position === CHECK_POSITION.RIGHT;
  });
});

const getTip = (answer: Answer) => {
  const { type } = answer.content[0];

  if (type === STEP_ANSWER_CONTENT_TYPE.RADIO_USES) {
    return getUsagePriceByAnswer(quizStore.answers, answer);
  }

  if (type === STEP_ANSWER_CONTENT_TYPE.RADIO_SHIPMENTS) {
    return getShippingPriceByAnswer(quizStore.answers, answer);
  }

  return answer.content[0].content.tip;
};
</script>

<template>
  <div
    class="checklist-answer f-row gap-y-10 sm:gap-y-20"
    :class="{ 'flex-col items-center': !isRow }"
  >
    <div
      v-if="leftAnswers.length"
      class="f-col-full"
      :class="columnClass"
    >
      <div
        v-if="leftTitle"
        class="text-16"
        v-html="leftTitle"
      />

      <ul class="mt-20 flex flex-col gap-10 first:mt-0 sm:gap-20">
        <li
          v-for="(item, index) in leftAnswers"
          :key="item.id"
          class="break-inside-avoid"
          :data-testid="!!modelValue.find(({id}) => id === item.id)
            ? `quiz-answer-${index}-selected`
            : `quiz-answer-${index}`"
        >
          <BaseCheck
            v-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.CHECKBOX"
            :model-value="values"
            :value="item"
            :size="20"
            :name="name"
            type="checkbox"
            styled
            @update:model-value="(value: Answer[]) => {
              if (item.exclusive) {
                values = (values as Answer[]).includes(item) ? [] : [item]
                return
              }
              values = value.filter(answer => !answer.exclusive)
            }"
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>

          <BaseCheck
            v-else-if="!grouped"
            v-model="values"
            :value="item"
            :size="20"
            :name="name"
            type="radio"
            styled
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>

          <BaseCheck
            v-else
            v-model="leftValues"
            :value="item"
            :size="20"
            :name="`${name}-left`"
            type="radio"
            styled
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>
        </li>
      </ul>
    </div>

    <div
      v-if="rightAnswers.length"
      class="f-col-full"
      :class="columnClass"
    >
      <div
        v-if="rightTitle"
        class="text-16"
        v-html="rightTitle"
      />

      <ul class="mt-20 flex flex-col gap-10 first:mt-0 sm:gap-20">
        <li
          v-for="(item, index) in rightAnswers"
          :key="item.id"
          class="break-inside-avoid"
          :data-testid="!!modelValue.find(({id}) => id === item.id)
            ? `quiz-answer-${index + leftAnswers.length}-selected`
            : `quiz-answer-${index + leftAnswers.length}`"
        >
          <BaseCheck
            v-if="item.content[0].type === STEP_ANSWER_CONTENT_TYPE.CHECKBOX"
            :model-value="values"
            :value="item"
            :size="20"
            :name="name"
            type="checkbox"
            styled
            @update:model-value="(value: Answer[]) => {
              if (item.exclusive) {
                values = (values as Answer[]).includes(item) ? [] : [item]
                return
              }
              values = value.filter(answer => !answer.exclusive)
            }"
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>

          <BaseCheck
            v-else-if="!grouped"
            v-model="values"
            :value="item"
            :size="20"
            :name="name"
            type="radio"
            styled
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>

          <BaseCheck
            v-else
            v-model="rightValues"
            :value="item"
            :size="20"
            :name="`${name}-right`"
            type="radio"
            styled
          >
            <span
              v-if="item.content[0].content.label"
              class="text-16 leading-120"
              v-html="item.content[0].content.label"
            />
            <span
              v-if="getTip(item)"
              class="text-14 text-primary-500"
              v-html="getTip(item)"
            />
          </BaseCheck>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
