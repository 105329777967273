<script setup lang="ts">
import { isEqual } from 'lodash';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  modelValue?: boolean | any[];
  value?: any;
  dataTestpl?: string;
}>(), {
  modelValue: false,
  value: null,
  dataTestpl: undefined,
});

const emit = defineEmits<{
  'update:modelValue': [value: any];
}>();

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const isChecked = computed(() => {
  return Array.isArray(model.value)
    ? model.value.some((item) => isEqual(item, props.value))
    : model.value;
});
</script>

<template>
  <label class="flex cursor-pointer items-center gap-14">
    <span
      class="bg-gray-100
        rounded-12
        flex
        h-[27px]
        w-[56px]
        shrink-0
        items-center
        rounded-full
        p-[5px]
        transition
        duration-200
        ease-in-out"
      :class="[
        {
          'bg-primary-500': isChecked,
          'bg-primary-200': !isChecked,
        }
      ]"
    >
      <input
        v-model="model"
        class="pointer-events-none absolute opacity-0"
        :value="value"
        type="checkbox"
        :data-testpl="dataTestpl"
      >

      <span class="relative h-full w-full">
        <span
          class="absolute
            left-0
            top-0
            h-[17px]
            w-[17px]
            rounded-full
            bg-white
            transition
            duration-200
            ease-in-out"
          :class="{
            'translate-x-[29px]': isChecked
          }"
        />
      </span>
    </span>

    <span class="text-12">
      <slot />
    </span>
  </label>
</template>
