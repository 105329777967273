import { apiCart } from '@api/cart';
import { apiQuiz } from '@api/quiz';
import { SessionInfoResponse } from '@models/user';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useUserStore } from './user';

export type AnalyticProduct = {
  idp: number;
  idv?: number;
  item_name: string;
  price: number;
  quantity: number;
  item_id?: string
  item_brand?: string
  item_category?: string
  item_list_id?: string
  item_list_name?: string
  index?: number
};

export const useAnalyticsStore = defineStore('analytics', () => {
  const userStore = useUserStore();

  const aid = ref(localStorage.getItem('aid'));
  const cid = ref(localStorage.getItem('cid'));
  const campaign = ref(localStorage.getItem('campaign'));
  const fbc = ref(localStorage.getItem('fbc'));
  const efTransactionId = ref(localStorage.getItem('_ef_transaction_id'));

  const facebookUserData = computed(() => {
    const data = {
      fbc: fbc.value,
      external_id: <number | null> null,
      em: <string | null> null,
      fn: <string | null> null,
      ln: <string | null> null,
      db: <number | null> null,
      ph: <string | null> null,
      ge: <string | null> null,
      country: <string | null> null,
      st: <string | null> null,
      zp: <string | null> null,
    };

    const userProfile = userStore.profileInfo?.user;

    if (userProfile) {
      const addr = userProfile.addresses.shippingAddress || userProfile.addresses.billingAddress;

      data.external_id = userProfile.id;
      data.em = userProfile.email.toLowerCase();
      data.fn = userProfile.firstName?.toLowerCase() || null;
      data.ln = userProfile.lastName?.toLowerCase() || null;
      data.db = userProfile.dob;
      data.ph = userProfile.phone?.replace('+', '') || null;
      data.ge = userProfile.gender?.toLowerCase() || null;
      data.country = (addr?.countryCode || 'us').toLowerCase();
      data.st = addr?.administrativeArea ? addr?.administrativeArea.toLowerCase() : null;
      data.zp = addr?.postalCode || null;
    }

    return data;
  });

  // Use this function to re-init analytics with updated user data, for now only for Meta/Facebook
  const initAnalytics = () => {
    const pixelId = import.meta.env.VITE_FACEBOOK_ADS_PIXEL_ID;
    const pixelIdAlt = import.meta.env.VITE_FACEBOOK_ADS_PIXEL_ID_ALT;

    [pixelId, pixelIdAlt].forEach((pid) => {
      const instance = window.fbq?.instance?.pixelsByID[pid];
      if (!instance) {
        // Initialize Facebook pixel, could be helpful once we move to full SPA,
        // because right now we run fbq('init') from Seomatic plugin (Tracking scripts)
        // Meta doesn't allow running init more than one time
        // resulting with "Duplicate Pixel ID" error
        window.fbq?.('init', pid, {
          ...facebookUserData.value,
        });
      } else {
        if (!instance.userData) {
          instance.userData = {};
        }
        Object.assign(instance.userData, facebookUserData.value);
      }
    });

    if (pixelId) {
      window.fbq?.('init', pixelId, {
        ...facebookUserData.value,
      });
    }

    if (pixelIdAlt) {
      window.fbq?.('init', pixelIdAlt, {
        ...facebookUserData.value,
      });
    }
  };

  // Use non-obvious event names for Meta
  // https://issues.yournextagency.com/issue/JRN-2628/Set-up-custom-Meta-events-throughout-funnel
  const EVENTS_MAPPING = {
    InitiateCheckout: 'EVENT_7367', // 'EVENT_IC'
    CompleteRegistration: 'EVENT_6782', // 'EVENT_CR'
    AddToCart: 'EVENT_6567', // 'EVENT_AC'
    RemoveFromCart: 'EVENT_8267', // 'EVENT_RC'
    Purchase: 'EVENT_8082', // 'EVENT_PR'
    ViewQuizStep: 'EVENT_8683', // 'EVENT_VS'
    SelectQuizAnswer: 'EVENT_8365', // 'EVENT_SA'
    AddPaymentInfo: 'EVENT_6573', // 'EVENT_AI'
    ViewListItem: 'EVENT_VL', // Google only
  };

  // Fire on the first page of the quiz
  const viewContent = (quizName: string) => {
    // eslint-disable-next-line no-console
    console.log('viewContent', { quizName });

    window.gtag?.('event', 'view_content', { name: quizName });
    window.fbq?.('track', 'ViewContent', {
      content_name: quizName,
      ...facebookUserData.value,
    });
    window.ttq?.track('ViewContent', { content_name: quizName });
  };

  const viewQuizStep = (stepId: number|string, stepSlug: string, stepName?: string) => {
    // eslint-disable-next-line no-console
    console.log('viewQuizStep', { stepId, stepSlug, stepName });
    const eventUuid = crypto.randomUUID();
    window.fbq?.('trackCustom', 'ViewQuizStep', {
      step_slug: stepSlug,
      step_name: stepName,
      ...facebookUserData.value,
    }, {
      eventID: eventUuid,
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.ViewQuizStep, {
      step_id: stepId,
      ...facebookUserData.value,
    }, {
      eventID: eventUuid,
    });

    apiQuiz.trackViewQuizStep({
      stepId: stepId,
      stepSlug: stepSlug,
      stepName: stepName,
      eventId: eventUuid,
    });

    window.gtag?.('event', 'view_quiz_step', {
      step_slug: stepSlug,
      step_name: stepName,
      transaction_id: eventUuid,
    });
    window.gtag?.('event', EVENTS_MAPPING.ViewQuizStep, {
      step_id: stepId,
      transaction_id: eventUuid,
    });
  };

  const selectQuizAnswer = (
    answerId: number[],
    answer: string[],
    stepId: number|string,
    stepSlug: string,
  ) => {
    // eslint-disable-next-line no-console
    console.log('selectQuizAnswer', { answer, stepSlug });
    const eventUuid = crypto.randomUUID();
    window.fbq?.('trackCustom', 'SelectQuizAnswer', {
      step_value: answer,
      step_slug: stepSlug,
      ...facebookUserData.value,
    }, {
      eventID: eventUuid,
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.SelectQuizAnswer, {
      step_id: stepId,
      answer_id: answerId,
      ...facebookUserData.value,
    }, {
      eventID: eventUuid,
    });

    apiQuiz.trackSelectQuizAnswer({
      stepSlug: stepSlug,
      stepValue: answer,
      stepId: stepId,
      answerId: answerId,
      eventId: eventUuid,
    });

    window.gtag?.('event', 'select_quiz_answer', {
      step_slug: stepSlug,
      step_value: answer,
      transaction_id: eventUuid,
    });
    window.gtag?.('event', EVENTS_MAPPING.SelectQuizAnswer, {
      step_id: stepId,
      answer_id: answerId,
      transaction_id: eventUuid,
    });
  };

  // Fire when the user looked at the pharmacy
  const viewPharmacy = (
    categoryId: number,
    categoryHandle: string,
    categoryName: string,
    items: AnalyticProduct[],
  ) => {
    // eslint-disable-next-line no-console
    console.log('viewPharmacy', { categoryHandle, categoryName, items });

    window.gtag?.('event', 'view_item_list', {
      item_list_id: categoryHandle,
      item_list_name: categoryName,
      currency: 'USD',
      items,
    });
    window.gtag?.('event', EVENTS_MAPPING.ViewListItem, {
      id: categoryId,
      content: items.map((item) => item.idv || item.idp),
    });
  };

  // Fire when User has registered
  const createAccount = (info: SessionInfoResponse) => {
    // eslint-disable-next-line no-console
    console.log('createAccount', { aid: aid.value });
    // eslint-disable-next-line no-console
    console.log('createAccount', { cid: cid.value });
    // eslint-disable-next-line no-console
    console.log('createAccount', { campaign: campaign.value });

    window.gtag?.('event', 'create_account', {
      transaction_id: info.id,
    });
    window.gtag?.('event', EVENTS_MAPPING.CompleteRegistration, {
      transaction_id: info.id,
    });
    window.fbq?.('track', 'CompleteRegistration', {
      ...facebookUserData.value,
    }, {
      eventID: info.id,
    });
    window.fbq?.('track', EVENTS_MAPPING.CompleteRegistration, {
      ...facebookUserData.value,
    }, {
      eventID: info.id,
    });
  };

  // After you add a product to the cart (either subscription or product)
  const addToCart = (
    items: AnalyticProduct[],
    value = 0,
    eventId: string|null = null,
  ) => {
    // eslint-disable-next-line no-console
    console.log('addToCart', { items, value, eventId });

    window.gtag?.('event', 'add_to_cart', {
      currency: 'USD',
      value,
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
      transaction_id: eventId,
    });

    window.gtag?.('event', EVENTS_MAPPING.AddToCart, {
      value,
      content: items.map((item) => item.idv || item.idp),
      q: items.map((item) => item.quantity),
      transaction_id: eventId,
    });

    window.fbq?.('track', 'AddToCart', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
      ...facebookUserData.value,
    }, {
      eventID: eventId,
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.AddToCart, {
      content_ids: items.map((item) => item.idv || item.idp),
      value,
      ...facebookUserData.value,
    }, {
      eventID: eventId,
    });

    window.ttq?.track('AddToCart', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
    });

    apiCart.trackAddToCart({
      eventId: eventId || '',
      currency: 'USD',
      value,
      items: items.map((item) => ({
        id: item.item_id,
        name: item.item_name,
        category: item.item_list_id,
        price: item.price,
        quantity: item.quantity,
      })),
    });
  };

  // After you remove a product to the cart  - click on minus
  const removeFromCart = (items: AnalyticProduct[], value = 0, eventId: string|null = null)  => {
    // eslint-disable-next-line no-console
    console.log('removeFromCart', { items, value });

    window.gtag?.('event', 'remove_from_cart', {
      currency: 'USD',
      value: value,
      items,
      transaction_id: eventId,
    });
    window.gtag?.('event', EVENTS_MAPPING.RemoveFromCart, {
      value: value,
      content: items.map((item) => item.idv || item.idp),
      transaction_id: eventId,
    });

    window.fbq?.('trackCustom', 'RemoveFromCart', {
      currency: 'USD',
      value: value,
      items,
      ...facebookUserData.value,
    }, {
      eventID: eventId,
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.RemoveFromCart, {
      value: value,
      items: items.map((item) => ({
        price: item.price,
        quantity: item.quantity,
        item_id: item.idv || item.idp,
        item_list_id: item.item_list_id,
        item_list_name: item.item_list_name,
        index: item.index,
      })),
      ...facebookUserData.value,
    }, {
      eventID: eventId,
    });
  };

  // When checkout page is opened
  const beginCheckout = (
    items: AnalyticProduct[],
    value = 0,
    cartId: number|null = null,
  ) => {
    // eslint-disable-next-line no-console
    console.log('beginCheckout', { items, value });

    window.gtag?.('event', 'begin_checkout', {
      currency: 'USD',
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
      value,
      transaction_id: cartId,
    });
    window.gtag?.('event', EVENTS_MAPPING.InitiateCheckout, {
      content: items.map((item) => item.idv || item.idp),
      q: items.map((item) => item.quantity),
      value,
      transaction_id: cartId,
    });

    window.fbq?.('track', 'InitiateCheckout', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
      ...facebookUserData.value,
    }, {
      eventID: cartId,
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.InitiateCheckout, {
      content_ids: items.map((item) => item.idv || item.idp),
      num_items: items.length,
      value,
      ...facebookUserData.value,
    }, {
      eventID: cartId,
    });

    window.ttq?.track('InitiateCheckout', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
    });
  };

  // After a successful purchase
  const purchase = (items: AnalyticProduct[], transactionId: string, value = 0, cartId: number) => {
    // eslint-disable-next-line no-console
    console.log('purchase', { items, transactionId, cartId, value, aid: aid.value });

    // Step 1 - add payment info
    window.gtag?.('event', 'add_payment_info', {
      currency: 'USD',
      payment_type: 'Credit Card', // TODO
      value: value,
      items,
      transaction_id: cartId,
    });
    window.gtag?.('event', EVENTS_MAPPING.AddPaymentInfo, {
      value: value,
      content: items.map((item) => item.idv || item.idp),
      transaction_id: cartId,
    });

    window.fbq?.('track', 'AddPaymentInfo', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
      ...facebookUserData.value,
    }, {
      eventID: String(cartId),
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.AddPaymentInfo, {
      content_ids: items.map((item) => item.idv || item.idp),
      value,
      ...facebookUserData.value,
    }, {
      eventID: String(cartId),
    });

    // Step 2 - add shipping info
    window.gtag?.('event', 'add_shipping_info', {
      currency: 'USD',
      value: value,
      items,
    });

    // Step 3 - purchase
    window.gtag?.('event', 'purchase', {
      transaction_id: transactionId,
      value: value,
      currency: 'USD',
      items: items.map((item) => ({
        item_name: item.item_name,
        price: item.price,
        quantity: item.quantity,
      })),
    });
    window.gtag?.('event', EVENTS_MAPPING.Purchase, {
      transaction_id: transactionId,
      value: value,
      content: items.map((item) => item.idv || item.idp),
      q: items.map((item) => item.quantity),
    });

    window.fbq?.('track', 'Purchase', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      value,
      num_item: items.length,
      ...facebookUserData.value,
    }, {
      eventID: String(cartId),
    });

    window.fbq?.('trackCustom', EVENTS_MAPPING.Purchase, {
      content_ids: items.map((item) => item.idv || item.idp),
      value,
      num_item: items.length,
      ...facebookUserData.value,
    }, {
      eventID: String(cartId),
    });

    window.ttq?.track('Purchase', {
      content_ids: items.map((item) => item.item_name),
      content_type: 'product',
      currency: 'USD',
      num_items: items.length,
      value,
    });

    window.VWO?.event('purchase', {
      purchaseValue: value,
    });
  };

  return {
    aid,
    campaign,
    fbc,
    efTransactionId,
    facebookUserData,
    initAnalytics,
    viewContent,
    createAccount,
    addToCart,
    beginCheckout,
    purchase,
    viewPharmacy,
    removeFromCart,
    viewQuizStep,
    selectQuizAnswer,
  };
});
