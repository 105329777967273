<script setup lang="ts">
import { StepContentBody } from '@models/quiz';
import { useQuizStore } from '@stores/index';
import { computed } from 'vue';

type Props = {
  content: StepContentBody
}

const props = defineProps<Props>();
const quizStore = useQuizStore();

const loseWeight = computed(() => {
  const finalWeight = quizStore.answerArray
    .find(({ id }) => id === props.content.goal?.[0])
    ?.value || 0;

  return +(weight.value - Number(finalWeight)).toFixed(2);
});

const weight = computed(() => {
  const value = quizStore.answerArray
    .find(({ id }) => id === props.content.bmi?.[0])
    ?.value || 0;

  return Number(value);
});

const height = computed(() => {
  const value = quizStore.answerArray
    .find(({ id }) => id === props.content.bmi?.[1])
    ?.value
    ?.split(/\s/)
    .map((v) => v.replace(/\D/g, ''))
    .map((v) => Number(v) || 0)
      || [0, 0];

  return value[0] * 12 + value[1];
});

const bmi = computed(() => {
  const value = (weight.value / Math.pow(height.value, 2)) * 703;
  return +value.toFixed(2);
});

const achievements = computed(() => {
  return quizStore.answerArray
    .filter(({ id }) => props.content.achievement?.includes(id))
    .map(({ content }) => content[0].content.label);
});

const mostGain = computed(() => {
  return quizStore.answerArray
    .find(({ id }) => props.content.mostGain?.includes(id))
    ?.content[0].content;
});
</script>

<template>
  <div class="quiz-summary">
    <div class="f-row">
      <div class="f-col-full sm:f-col-8 sm:px-40">
        <h1
          class="text-26 sm:text-50 sm:font-extralight sm:leading-none"
          v-html="content.header"
        />
        <p class="text mt-30 text-16 sm:mt-40">
          {{ content.subheader }}
        </p>
      </div>
    </div>

    <div class="f-row mt-30 gap-20 sm:mt-40">
      <div class="lg:f-col-4 w-full">
        <img
          v-if="content.photo"
          class="h-auto w-full rounded-[5px]"
          :src="content.photo"
          alt=""
        >
      </div>
      <div class="lg:f-col-4 w-full">
        <div class="flex items-center gap-20 rounded-[5px] bg-primary-500 p-20 sm:px-40 sm:py-30">
          <div class="leading-140 tracking-130 min-w-[100px] text-13 font-light uppercase text-primary-100">
            Your <br>goal
          </div>
          <div class="text-27 font-medium leading-none text-primary-100">
            Lose {{ loseWeight }} lbs.
          </div>
        </div>
        <div class="mt-20 flex flex-col gap-x-20 gap-y-32 rounded-[5px] bg-white p-20 sm:flex-row sm:px-40 sm:py-30">
          <div class="leading-140 tracking-130 min-w-[100px] text-13 uppercase text-purple">
            Your starting point
          </div>
          <ul class="w-full font-medium">
            <li class="border-light-grey mb-20 border-b-[1px] pb-20">
              Weight: {{ weight }}lbs.
            </li>
            <li class="border-light-grey mb-20 border-b-[1px] pb-20">
              BMI of {{ bmi }}
            </li>
            <li class="border-light-grey mb-20 border-b-[1px] pb-20">
              <div>Have tried:</div>
              <ul class="list-disc">
                <li
                  v-for="(achievement, index) in achievements"
                  :key="index"
                  class="ml-24 pt-10 text-14"
                  v-html="achievement"
                />
              </ul>
            </li>
            <li>
              Most weight gain: {{ mostGain?.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
